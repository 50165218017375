import React, { Fragment } from 'react';
import Map from 'pigeon-maps';
import geolib from 'geolib';
import calculateCenterAndZoom from '../utils/calculateCenterAndZoom';

let sqrtOf2 = Math.sqrt(2);

class Marker extends React.Component {
  state = { showDetails: false };

  onClick = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render = () => {
    const { showDetails } = this.state;
    const { location, latLngToPixel } = this.props;
    const { position, radius } = location;
    const p1 = geolib.computeDestinationPoint(
      { latitude: position.lat, longitude: position.lon },
      sqrtOf2 * radius,
      225
    );
    const p2 = geolib.computeDestinationPoint(
      { latitude: position.lat, longitude: position.lon },
      sqrtOf2 * radius,
      45
    );
    const [left] = latLngToPixel([p1.latitude, p1.longitude]);
    const [right, top] = latLngToPixel([p2.latitude, p2.longitude]);
    const elementRadius = right - left;
    const style = {
      position: 'absolute',
      left: left,
      top: top,
      width: elementRadius,
      height: elementRadius,
      borderRadius: elementRadius,
      backgroundColor: 'rgba(255,0,0,0.3)',
      textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px  1px 1px white, -1px -1px 1px white',
    };
    return (
      <div style={style} onClick={this.onClick}>
        {showDetails ? (
          <Fragment>
            {location.locationId} - {location.name}
            <br />
            {location.position.lon}, {location.position.lat}
            <br />
            {location.radius}m<br />
          </Fragment>
        ) : (
          location.locationId
        )}
      </div>
    );
  };
}

export default class WLocationsMap extends React.Component {
  constructor(props) {
    super(props);
    const { locations, height } = this.props;
    const { center, zoom } =
      locations.length > 0
        ? calculateCenterAndZoom(locations.map(x => x.position), height)
        : {
            center: { latitude: 0, longitude: 0 },
            zoom: 1,
          };
    this.state = {
      center: [center.latitude, center.longitude],
      zoom,
    };
  }

  onBoundsChanged = ({ center, zoom }) => {
    // just for exp
    console.log('onBoundsChanged', center, zoom);
  };

  componentWillReceiveProps = nextProps => {
    // just check for same object
    if (nextProps.locations !== this.props.location) {
      const { locations, height } = nextProps;
      if (locations.length > 0) {
        const { center, zoom } = calculateCenterAndZoom(locations.map(x => x.position), height);
        this.setState({ center: [center.latitude, center.longitude], zoom });
      }
    }
  };

  render = () => {
    const { locations, width, height } = this.props;

    if (locations.length < 1) {
      return null;
    }

    const { center, zoom } = this.state;
    return (
      <Map
        provider={(x, y, z) => {
          const s = String.fromCharCode(97 + ((x + y + z) % 3));
          return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        width={width}
        height={height}
        onBoundsChanged={this.onBoundsChanged}
        // provider={(x, y, z) => `http://tiles.openrailwaymap.org/standard/${z}/${x}/${y}.png`}
        // attributionPrefix={<a href="https://github.com/mariusandra/pigeon-maps">Pigeon</a>}
        // attribution={
        //   <span>
        //     <a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style:{' '}
        //     <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a>{' '}
        //     <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap{' '}
        //   </span>
        // }
      >
        {locations.map(location => (
          <Marker
            key={location.locationId}
            anchor={[location.position.lat, location.position.lon]}
            location={location}
          />
        ))}
      </Map>
    );
  };
}
