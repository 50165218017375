// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var WRouting = require("../navigation/WRouting.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WAuthManager = require("../auth/WAuthManager.bs.js");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js");

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexGrow(1),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.justifyContent(/* spaceAround */-485895757),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = /* module */[/* buttonContainer */buttonContainer];

var component = ReasonReact.statelessComponent("WMainScreen");

function make(userInfo, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: buttonContainer
                        }, ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                                    return WRouting.RouterConfig[/* push */2](/* Locations */1);
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WMainScreen.locations", "Locations", undefined, undefined, /* array */[]))])), ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                                    return WRouting.RouterConfig[/* push */2](/* Trains */Block.__(1, [Time.LocalDate[/* make */3](/* () */0)]));
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WMainScreen.trains", "Trains", undefined, undefined, /* array */[]))])), ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                                    return WRouting.RouterConfig[/* push */2](/* Trackers */Block.__(0, [
                                                  Time.Instant[/* plusHours */5](Time.Instant[/* make */2](/* () */0), -24),
                                                  undefined
                                                ]));
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WMainScreen.trackers", "Trackers", undefined, undefined, /* array */[]))])), WAuthManager.hasRole(userInfo, "ROLE_TRAIN_TRACKER_TRACKER_MAPPER") ? ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                                      return WRouting.RouterConfig[/* push */2](/* TrackerMappings */2);
                                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WMainScreen.trackerMappings", "Tracker Mappings", undefined, undefined, /* array */[]))])) : null);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* buttonContainer Not a pure module */
