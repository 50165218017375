// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");

var KeycloakException = Caml_exceptions.create("KeycloakAdapter.KeycloakException");

function make(prim, prim$1, prim$2) {
  return {
          url: prim,
          realm: prim$1,
          clientId: prim$2
        };
}

function openIdConfigUrl(config) {
  return config.url + ("/realms/" + (config.realm + "/.well-known/openid-configuration"));
}

var KeycloakOpenIdConfig = /* module */[
  /* make */make,
  /* openIdConfigUrl */openIdConfigUrl
];

function make$1(prim, prim$1, prim$2, prim$3, prim$4, prim$5, prim$6, prim$7, prim$8, _) {
  var tmp = { };
  if (prim !== undefined) {
    tmp.onLoad = Js_primitive.valFromOption(prim);
  }
  if (prim$1 !== undefined) {
    tmp.token = Js_primitive.valFromOption(prim$1);
  }
  if (prim$2 !== undefined) {
    tmp.refreshToken = Js_primitive.valFromOption(prim$2);
  }
  if (prim$3 !== undefined) {
    tmp.idToken = Js_primitive.valFromOption(prim$3);
  }
  if (prim$4 !== undefined) {
    tmp.timeSkew = Js_primitive.valFromOption(prim$4);
  }
  if (prim$5 !== undefined) {
    tmp.checkLoginIframe = Js_primitive.valFromOption(prim$5);
  }
  if (prim$6 !== undefined) {
    tmp.checkLoginIframeInterval = Js_primitive.valFromOption(prim$6);
  }
  if (prim$7 !== undefined) {
    tmp.responseMode = Js_primitive.valFromOption(prim$7);
  }
  if (prim$8 !== undefined) {
    tmp.flow = Js_primitive.valFromOption(prim$8);
  }
  return tmp;
}

var InitOptions = /* module */[/* make */make$1];

function make$2(prim, prim$1, prim$2, prim$3, prim$4, prim$5, _) {
  var tmp = { };
  if (prim !== undefined) {
    tmp.redirectUri = Js_primitive.valFromOption(prim);
  }
  if (prim$1 !== undefined) {
    tmp.prompt = Js_primitive.valFromOption(prim$1);
  }
  if (prim$2 !== undefined) {
    tmp.maxAge = Js_primitive.valFromOption(prim$2);
  }
  if (prim$3 !== undefined) {
    tmp.loginHint = Js_primitive.valFromOption(prim$3);
  }
  if (prim$4 !== undefined) {
    tmp.action = Js_primitive.valFromOption(prim$4);
  }
  if (prim$5 !== undefined) {
    tmp.locale = Js_primitive.valFromOption(prim$5);
  }
  return tmp;
}

var LoginOptions = /* module */[/* make */make$2];

function make$3(prim, _) {
  var tmp = { };
  if (prim !== undefined) {
    tmp.redirectUri = Js_primitive.valFromOption(prim);
  }
  return tmp;
}

var LogoutOptions = /* module */[/* make */make$3];

function make$4(prim) {
  return {
          roles: prim
        };
}

var Access = /* module */[/* make */make$4];

function make$5(prim, prim$1, prim$2, prim$3, prim$4, prim$5, _) {
  var tmp = {
    preferred_username: prim,
    name: prim$3
  };
  if (prim$1 !== undefined) {
    tmp.given_name = Js_primitive.valFromOption(prim$1);
  }
  if (prim$2 !== undefined) {
    tmp.family_name = Js_primitive.valFromOption(prim$2);
  }
  if (prim$4 !== undefined) {
    tmp.email = Js_primitive.valFromOption(prim$4);
  }
  if (prim$5 !== undefined) {
    tmp.realm_access = Js_primitive.valFromOption(prim$5);
  }
  return tmp;
}

var TokenParsed = /* module */[/* make */make$5];

function toJsPromise(keycloakPromise) {
  return new Promise((function (resolve, reject) {
                keycloakPromise.success((function (result) {
                          return resolve(result);
                        })).error((function (err) {
                        return reject([
                                    KeycloakException,
                                    err
                                  ]);
                      }));
                return /* () */0;
              }));
}

var KeycloakPromise = /* module */[/* toJsPromise */toJsPromise];

function init(keycloak, options) {
  return toJsPromise(keycloak.init(options));
}

function updateToken(keycloak, minValidity) {
  return toJsPromise(keycloak.updateToken(minValidity));
}

var Keycloak = /* module */[
  /* init */init,
  /* updateToken */updateToken
];

exports.KeycloakException = KeycloakException;
exports.KeycloakOpenIdConfig = KeycloakOpenIdConfig;
exports.InitOptions = InitOptions;
exports.LoginOptions = LoginOptions;
exports.LogoutOptions = LogoutOptions;
exports.Access = Access;
exports.TokenParsed = TokenParsed;
exports.KeycloakPromise = KeycloakPromise;
exports.Keycloak = Keycloak;
/* No side effect */
