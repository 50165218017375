// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Api_bs = require("../../common/atd/Api_bs.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var WTrackMap = require("./WTrackMap");
var ArrayLabels = require("bs-platform/lib/js/arrayLabels.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function make(waypoints, width, height, children) {
  return ReasonReact.wrapJsForReason(WTrackMap.default, {
              waypoints: Belt_Array.map(ArrayLabels.of_list(waypoints), Api_bs.write_waypoint),
              width: width,
              height: height
            }, children);
}

exports.make = make;
/* Api_bs Not a pure module */
