// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var ReactIntl = require("react-intl");
var De = require("react-intl/locale-data/de");
var Fr = require("react-intl/locale-data/fr");

function init() {
  ReactIntl.addLocaleData(Fr);
  ReactIntl.addLocaleData(De);
  return /* () */0;
}

exports.init = init;
/* react-intl Not a pure module */
