import './intl';

// All of the following are required for IE 11.
// Most of this is copied from create-react-app's react-app-polyfill package.
// But we don't need its fetch polyfill here, as we are using axios.

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// The following imports will only apply the polyfills when required,
// otherwise the native implementation is still used.

Object.assign = require('object-assign');

require('core-js/fn/string/starts-with');
require('core-js/fn/string/ends-with');
require('core-js/fn/string/includes');
// require('core-js/fn/string/pad-start');
// require('core-js/fn/string/pad-end');
