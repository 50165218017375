// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Common_bs = require("./atd/Common_bs.bs.js");
var VersionJson = require("./version.json");

var versionInfo = Curry._1(Common_bs.read_versionInfo, VersionJson);

var versionString = versionInfo[/* version */0] + (" (" + (versionInfo[/* build */1] + ")"));

exports.versionInfo = versionInfo;
exports.versionString = versionString;
/* versionInfo Not a pure module */
