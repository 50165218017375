// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var DeJson = require("./i18n/de.json");
var EnJson = require("./i18n/en.json");
var FrJson = require("./i18n/fr.json");

function get(locale) {
  var match = locale.substring(0, 2);
  switch (match) {
    case "de" : 
        return Js_primitive.some(DeJson);
    case "en" : 
        return Js_primitive.some(EnJson);
    case "fr" : 
        return Js_primitive.some(FrJson);
    default:
      return undefined;
  }
}

exports.get = get;
/* ./i18n/de.json Not a pure module */
