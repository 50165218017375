// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Common_bs = require("./common/atd/Common_bs.bs.js");
var LicenseListJson = require("./licenseList.json");

var licenses = Curry._1(Common_bs.read_licenseList, LicenseListJson);

exports.licenses = licenses;
/* licenses Not a pure module */
