// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';


function dbError(message) {
  return /* record */[
          /* errorType : DbError */0,
          /* message */message
        ];
}

function jsonDecodeError(message) {
  return /* record */[
          /* errorType : JsonDecodeError */1,
          /* message */message
        ];
}

function toString(error) {
  var errorTypeString = error[/* errorType */0] ? "jsonDecode" : "db";
  return errorTypeString + (" (" + (error[/* message */1] + ")"));
}

exports.dbError = dbError;
exports.jsonDecodeError = jsonDecodeError;
exports.toString = toString;
/* No side effect */
