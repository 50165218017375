// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Auth = require("../../common/auth/Auth.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var WMenu = require("./WMenu.bs.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WMenuButton = require("./WMenuButton.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var WNavbarAppItem = require("./WNavbarAppItem.bs.js");
var WNavbar_Styles = require("./WNavbar_Styles.bs.js");
var AppIconPng = require("../css/appIcon.png");
var WNavbarConfigItems = require("./WNavbarConfigItems.bs.js");

var component = ReasonReact.reducerComponent("WNavbar");

function make(auth, config, realm, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var theme = config[/* theme */3];
              var match = self[/* state */1];
              var anchorElement = match[/* anchorElement */1];
              var menuOpen = match[/* menuOpen */0];
              var userInfo = auth[/* userInfo */0];
              var userDisplayName = Auth.getDisplayName(userInfo);
              var onClick = function (evt) {
                return Curry._1(self[/* send */3], /* ToggleMenu */[evt.currentTarget]);
              };
              return React.createElement("div", {
                          className: WNavbar_Styles.navbar
                        }, React.createElement("div", {
                              className: WNavbar_Styles.left
                            }, ReasonReact.element(undefined, undefined, WNavbarAppItem.make(config[/* siteName */0], AppIconPng, /* array */[])), ReasonReact.element(undefined, undefined, WNavbarConfigItems.make(/* [] */0, "en", userInfo, /* array */[]))), React.createElement("div", {
                              className: WNavbar_Styles.right
                            }, ReasonReact.element(undefined, undefined, WMenuButton.make(userDisplayName, realm, menuOpen, onClick, /* array */[])), anchorElement !== undefined ? ReasonReact.element(undefined, undefined, WMenu.make(menuOpen, config, /* `ObjectGeneric */[
                                        -317959944,
                                        Js_primitive.valFromOption(anchorElement)
                                      ], (function () {
                                          return Curry._1(self[/* send */3], /* CloseMenu */0);
                                        }), auth[/* logout */2], theme, /* array */[])) : ReasonReact.element(undefined, undefined, WMenu.make(false, config, undefined, (function () {
                                          return Curry._1(self[/* send */3], /* CloseMenu */0);
                                        }), auth[/* logout */2], theme, /* array */[]))));
            }),
          /* initialState */(function () {
              return /* record */[
                      /* menuOpen */false,
                      /* anchorElement */undefined
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [/* record */[
                            /* menuOpen */!state[/* menuOpen */0],
                            /* anchorElement */Js_primitive.some(action[0])
                          ]]);
              } else {
                return /* Update */Block.__(0, [/* record */[
                            /* menuOpen */false,
                            /* anchorElement */undefined
                          ]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Styles = 0;

var Router = 0;

exports.Styles = Styles;
exports.Router = Router;
exports.component = component;
exports.make = make;
/* component Not a pure module */
