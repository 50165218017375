// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Colors = require("../../common/Colors.bs.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/src/MaterialUi_Paper.bs.js");

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.flex(1),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors.greyBackground)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.padding(Css.px(40)),
      /* :: */[
        Css.whiteSpace(/* preLine */538440119),
        /* [] */0
      ]
    ]);

var Styles = /* module */[
  /* container */container,
  /* text */text
];

var component = ReasonReact.statelessComponent("ErrorView");

function make(children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: container
                        }, ReasonReact.element(undefined, undefined, MaterialUi_Paper.make(undefined, undefined, undefined, undefined, /* :: */[
                                  /* Root */Block.__(0, [text]),
                                  /* [] */0
                                ], undefined, /* array */[ReactDOMRe.createElementVariadic("div", {
                                        className: text
                                      }, children)])));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* container Not a pure module */
