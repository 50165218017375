// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Styles = require("../css/Styles.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var DateTimePicker = require("../components/DateTimePicker.bs.js");

var component = ReasonReact.statelessComponent("WTrackersFilter");

function make(fromTime, toTime, onChangeDate, $staropt$star, _) {
  var extraItem = $staropt$star !== undefined ? Js_primitive.valFromOption($staropt$star) : null;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: Styles.Toolbar[/* container */0]
                        }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersFilter.fromTime", "From", undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, DateTimePicker.make((function (date) {
                                    if (date !== undefined) {
                                      return Curry._2(onChangeDate, date, toTime);
                                    } else {
                                      return /* () */0;
                                    }
                                  }), fromTime, Belt_Option.getWithDefault(toTime, Time.Instant[/* make */2](/* () */0)), undefined, Styles.Toolbar[/* picker */2], true, undefined, /* array */[])), ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersFilter.toTime", "to", undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, DateTimePicker.make((function (date) {
                                    return Curry._2(onChangeDate, fromTime, date);
                                  }), toTime, Time.Instant[/* make */2](/* () */0), fromTime, Styles.Toolbar[/* picker */2], undefined, undefined, /* array */[])), extraItem);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var spacer = React.createElement("div", {
      className: Styles.Toolbar[/* spacer */1]
    });

exports.component = component;
exports.make = make;
exports.spacer = spacer;
/* component Not a pure module */
