// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Repromise = require("@aantron/repromise/src/js/repromise.js");
var KeycloakJs = require("keycloak-js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var KeycloakAdapter = require("./bindings/KeycloakAdapter.bs.js");

function initKeycloak(keycloakConfig) {
  var keycloak = KeycloakJs(keycloakConfig);
  return Repromise.Rejectable[/* catch */7]((function (err) {
                console.log("Error initializing keycloak", err);
                return Repromise.resolved(undefined);
              }), Repromise.Rejectable[/* map */5]((function () {
                    return Js_primitive.some(keycloak);
                  }), Repromise.Rejectable[/* fromJsPromise */10](KeycloakAdapter.Keycloak[/* init */0](keycloak, KeycloakAdapter.InitOptions[/* make */0]("login-required", undefined, undefined, undefined, undefined, false, undefined, undefined, undefined, /* () */0)))));
}

exports.initKeycloak = initKeycloak;
/* Repromise Not a pure module */
