import React from 'react';
import Map from 'pigeon-maps';
import calculateCenterAndZoom from '../utils/calculateCenterAndZoom';

class Marker extends React.Component {
  render = () => {
    // const { showDetails } = this.state;
    const { top, left } = this.props;
    const style = {
      position: 'absolute',
      left: left,
      top: top,
      width: 5,
      height: 5,
      borderRadius: 5,
      backgroundColor: 'rgba(255,0,0,0.6)',
    };
    return <div style={style} />;
  };
}

export default class WTrackMap extends React.Component {
  constructor(props) {
    super(props);
    const { waypoints, height } = this.props;
    const { center, zoom } =
      waypoints.length > 0
        ? calculateCenterAndZoom(waypoints.map(x => x.position), height)
        : {
            center: { latitude: 0, longitude: 0 },
            zoom: 1,
          };
    this.state = {
      center: [center.latitude, center.longitude],
      zoom,
    };
  }

  onBoundsChanged = ({ center, zoom }) => {
    // just for exp
    console.log('onBoundsChanged', center, zoom);
  };

  componentWillReceiveProps = nextProps => {
    // just check for same object
    if (nextProps.waypoints !== this.props.waypoints) {
      const { waypoints, height } = nextProps;
      if (waypoints.length > 0) {
        const { center, zoom } = calculateCenterAndZoom(nextProps.waypoints.map(x => x.position), height);
        this.setState({ center: [center.latitude, center.longitude], zoom });
      }
    }
  };

  render = () => {
    const { waypoints, width, height } = this.props;

    if (waypoints.length < 1) return null;

    const { center, zoom } = this.state;
    return (
      <Map
        provider={(x, y, z) => {
          const s = String.fromCharCode(97 + ((x + y + z) % 3));
          return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        width={width}
        height={height}
        onBoundsChanged={this.onBoundsChanged}
        // provider={(x, y, z) => `http://tiles.openrailwaymap.org/standard/${z}/${x}/${y}.png`}
        // attributionPrefix={<a href="https://github.com/mariusandra/pigeon-maps">Pigeon</a>}
        // attribution={
        //   <span>
        //     <a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style:{' '}
        //     <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a>{' '}
        //     <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap{' '}
        //   </span>
        // }
      >
        {waypoints.map((waypoint, index) => (
          <Marker key={index} anchor={[waypoint.position.lat, waypoint.position.lon]} waypoint={waypoint} />
        ))}
      </Map>
    );
  };
}
