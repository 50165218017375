// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Atd_Adapters = require("./Atd_Adapters.bs.js");
var Atdgen_codec_runtime = require("@ahrefs/bs-atdgen-codec-runtime/src/atdgen_codec_runtime.bs.js");

var partial_arg = Atdgen_codec_runtime.Encode[/* string */3];

var partial_arg$1 = Atd_Adapters.JsDateTime[/* unwrap */1];

var partial_arg$2 = Atdgen_codec_runtime.Encode[/* contramap */20];

function write__1(param) {
  return partial_arg$2(partial_arg$1, partial_arg, param);
}

var partial_arg$3 = Atdgen_codec_runtime.Decode[/* string */7];

var partial_arg$4 = Atd_Adapters.JsDateTime[/* wrap */0];

var partial_arg$5 = Atdgen_codec_runtime.Decode[/* map */18];

function read__1(param) {
  return partial_arg$5(partial_arg$4, partial_arg$3, param);
}

var partial_arg$6 = Atdgen_codec_runtime.Encode[/* string */3];

var partial_arg$7 = Atd_Adapters.JsDate[/* unwrap */1];

var partial_arg$8 = Atdgen_codec_runtime.Encode[/* contramap */20];

function write__2(param) {
  return partial_arg$8(partial_arg$7, partial_arg$6, param);
}

var partial_arg$9 = Atdgen_codec_runtime.Decode[/* string */7];

var partial_arg$10 = Atd_Adapters.JsDate[/* wrap */0];

var partial_arg$11 = Atdgen_codec_runtime.Decode[/* map */18];

function read__2(param) {
  return partial_arg$11(partial_arg$10, partial_arg$9, param);
}

var write_geoPosition = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* float */4], "lon", t[/* lon */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* float */4], "lat", t[/* lat */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_geoPosition = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* float */5];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* float */5];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* lon */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("lon", partial_arg, param);
                      }), json),
                /* lat */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("lat", partial_arg$2, param);
                      }), json)
              ];
      }));

Atdgen_codec_runtime.Decode[/* option_as_constr */25](Atdgen_codec_runtime.Decode[/* bool */3]);

Atdgen_codec_runtime.Decode[/* option_as_constr */25](Atdgen_codec_runtime.Decode[/* float */5]);

Atdgen_codec_runtime.Decode[/* option_as_constr */25](read__2);

Atdgen_codec_runtime.Decode[/* option_as_constr */25](Atdgen_codec_runtime.Decode[/* string */7]);

var write_waypoint = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trackerId", t[/* trackerId */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "vehicleId", t[/* vehicleId */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trainNumber", t[/* trainNumber */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field_o */12](undefined, write__2, "operationDay", t[/* operationDay */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field */11](undefined, write__1, "gpsTime", t[/* gpsTime */4]),
                            /* :: */[
                              Atdgen_codec_runtime.Encode[/* field */11](undefined, write_geoPosition, "position", t[/* position */5]),
                              /* :: */[
                                Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* float */4], "altitude", t[/* altitude */6]),
                                /* :: */[
                                  Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* float */4], "accuracy", t[/* accuracy */7]),
                                  /* :: */[
                                    Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* float */4], "speed", t[/* speed */8]),
                                    /* :: */[
                                      Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* bool */6], "isMoving", t[/* isMoving */9]),
                                      /* :: */[
                                        Atdgen_codec_runtime.Encode[/* field */11](undefined, write__1, "serverReceiveTime", t[/* serverReceiveTime */10]),
                                        /* :: */[
                                          Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* float */4], "delayInMillis", t[/* delayInMillis */11]),
                                          /* :: */[
                                            Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "locationId", t[/* locationId */12]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_waypoint = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$6 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$7 = Atdgen_codec_runtime.Decode[/* float */5];
        var partial_arg$8 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* trackerId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("trackerId", partial_arg, param);
                      }), json),
                /* vehicleId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("vehicleId", partial_arg$2, param);
                      }), json),
                /* trainNumber */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("trainNumber", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* operationDay */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("operationDay", read__2), json),
                /* gpsTime */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$4("gpsTime", read__1, param);
                      }), json),
                /* position */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$5("position", read_geoPosition, param);
                      }), json),
                /* altitude */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("altitude", Atdgen_codec_runtime.Decode[/* float */5]), json),
                /* accuracy */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("accuracy", Atdgen_codec_runtime.Decode[/* float */5]), json),
                /* speed */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("speed", Atdgen_codec_runtime.Decode[/* float */5]), json),
                /* isMoving */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("isMoving", Atdgen_codec_runtime.Decode[/* bool */3]), json),
                /* serverReceiveTime */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$6("serverReceiveTime", read__1, param);
                      }), json),
                /* delayInMillis */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$8("delayInMillis", partial_arg$7, param);
                      }), json),
                /* locationId */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("locationId", Atdgen_codec_runtime.Decode[/* string */7]), json)
              ];
      }));

var write_role = Atdgen_codec_runtime.Encode[/* make */0]((function () {
        return Atdgen_codec_runtime.Encode[/* constr0 */18]("TRAIN_TRACKER_USER");
      }));

var read_role = Atdgen_codec_runtime.Decode[/* enum */23](/* :: */[
      /* tuple */[
        "TRAIN_TRACKER_USER",
        /* `Single */[
          -306849112,
          /* TRAIN_TRACKER_USER */-887793207
        ]
      ],
      /* [] */0
    ]);

var write_postTrackerMappingRequest = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trackerId", t[/* trackerId */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "vehicleId", t[/* vehicleId */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_postTrackerMappingRequest = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* trackerId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("trackerId", partial_arg, param);
                      }), json),
                /* vehicleId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("vehicleId", partial_arg$2, param);
                      }), json)
              ];
      }));

var write_postLongPollingCreateSessionResponse = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "sessionId", t[/* sessionId */0]),
                    /* [] */0
                  ]);
      }));

var read_postLongPollingCreateSessionResponse = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[/* sessionId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("sessionId", partial_arg, param);
                      }), json)];
      }));

var write_postLongPollingCreateSessionRequest = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "deviceId", t[/* deviceId */0]),
                    /* [] */0
                  ]);
      }));

var read_postLongPollingCreateSessionRequest = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        return /* record */[/* deviceId */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("deviceId", Atdgen_codec_runtime.Decode[/* string */7]), json)];
      }));

var write_longPollingAction = Atdgen_codec_runtime.Encode[/* make */0]((function (x) {
        if (x >= 492530731) {
          return Atdgen_codec_runtime.Encode[/* constr0 */18]("DELETE");
        } else {
          return Atdgen_codec_runtime.Encode[/* constr0 */18]("UPSERT");
        }
      }));

var read_longPollingAction = Atdgen_codec_runtime.Decode[/* enum */23](/* :: */[
      /* tuple */[
        "UPSERT",
        /* `Single */[
          -306849112,
          /* UPSERT */-1015714641
        ]
      ],
      /* :: */[
        /* tuple */[
          "DELETE",
          /* `Single */[
            -306849112,
            /* DELETE */492530731
          ]
        ],
        /* [] */0
      ]
    ]);

var write_json = Atd_Adapters.JsJson[/* write_json */0];

var read_json = Atd_Adapters.JsJson[/* read_json */1];

Atdgen_codec_runtime.Decode[/* option_as_constr */25](read_role);

var write_longPollingMessage = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "type", t[/* type_ */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field_o */12](undefined, write_role, "role", t[/* role */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, write_longPollingAction, "action", t[/* action */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, write_json, "payload", t[/* payload */3]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]);
      }));

var read_longPollingMessage = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* type_ */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("type", partial_arg, param);
                      }), json),
                /* role */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("role", read_role), json),
                /* action */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$2("action", read_longPollingAction, param);
                      }), json),
                /* payload */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("payload", read_json, param);
                      }), json)
              ];
      }));

var write_getTrainsResponseItem = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trainNumber", t[/* trainNumber */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, write__2, "operationDay", t[/* operationDay */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "vehicleId", t[/* vehicleId */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trackerId", t[/* trackerId */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field */11](undefined, write__1, "firstGpsTime", t[/* firstGpsTime */4]),
                            /* :: */[
                              Atdgen_codec_runtime.Encode[/* field */11](undefined, write__1, "lastGpsTime", t[/* lastGpsTime */5]),
                              /* :: */[
                                Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* int */5], "numberOfWaypoints", t[/* numberOfWaypoints */6]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_getTrainsResponseItem = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$6 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$7 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$8 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$9 = Atdgen_codec_runtime.Decode[/* int */4];
        var partial_arg$10 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* trainNumber */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("trainNumber", partial_arg, param);
                      }), json),
                /* operationDay */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$2("operationDay", read__2, param);
                      }), json),
                /* vehicleId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$4("vehicleId", partial_arg$3, param);
                      }), json),
                /* trackerId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$6("trackerId", partial_arg$5, param);
                      }), json),
                /* firstGpsTime */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$7("firstGpsTime", read__1, param);
                      }), json),
                /* lastGpsTime */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$8("lastGpsTime", read__1, param);
                      }), json),
                /* numberOfWaypoints */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$10("numberOfWaypoints", partial_arg$9, param);
                      }), json)
              ];
      }));

var partial_arg$12 = Atdgen_codec_runtime.Encode[/* list */8];

function write__12(param) {
  return partial_arg$12(write_getTrainsResponseItem, param);
}

var partial_arg$13 = Atdgen_codec_runtime.Decode[/* list */11];

function read__12(param) {
  return partial_arg$13(read_getTrainsResponseItem, param);
}

var write_getTrackersResponseItem = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trackerId", t[/* trackerId */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "vehicleId", t[/* vehicleId */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, write__1, "firstGpsTime", t[/* firstGpsTime */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, write__1, "lastGpsTime", t[/* lastGpsTime */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* int */5], "numberOfWaypoints", t[/* numberOfWaypoints */4]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_getTrackersResponseItem = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$6 = Atdgen_codec_runtime.Decode[/* int */4];
        var partial_arg$7 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* trackerId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("trackerId", partial_arg, param);
                      }), json),
                /* vehicleId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("vehicleId", partial_arg$2, param);
                      }), json),
                /* firstGpsTime */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$4("firstGpsTime", read__1, param);
                      }), json),
                /* lastGpsTime */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$5("lastGpsTime", read__1, param);
                      }), json),
                /* numberOfWaypoints */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$7("numberOfWaypoints", partial_arg$6, param);
                      }), json)
              ];
      }));

var partial_arg$14 = Atdgen_codec_runtime.Encode[/* list */8];

function write__11(param) {
  return partial_arg$14(write_getTrackersResponseItem, param);
}

var partial_arg$15 = Atdgen_codec_runtime.Decode[/* list */11];

function read__11(param) {
  return partial_arg$15(read_getTrackersResponseItem, param);
}

var write_getTrackerMappingsResponseItem = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "trackerId", t[/* trackerId */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "vehicleId", t[/* vehicleId */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_getTrackerMappingsResponseItem = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* trackerId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("trackerId", partial_arg, param);
                      }), json),
                /* vehicleId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("vehicleId", partial_arg$2, param);
                      }), json)
              ];
      }));

var partial_arg$16 = Atdgen_codec_runtime.Encode[/* list */8];

function write__10(param) {
  return partial_arg$16(write_getTrackerMappingsResponseItem, param);
}

var partial_arg$17 = Atdgen_codec_runtime.Decode[/* list */11];

function read__10(param) {
  return partial_arg$17(read_getTrackerMappingsResponseItem, param);
}

var partial_arg$18 = Atdgen_codec_runtime.Encode[/* list */8];

function write__9(param) {
  return partial_arg$18(write_longPollingMessage, param);
}

var partial_arg$19 = Atdgen_codec_runtime.Decode[/* list */11];

function read__9(param) {
  return partial_arg$19(read_longPollingMessage, param);
}

var write_getLongPollingResponse = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, write__9, "messages", t[/* messages */0]),
                    /* [] */0
                  ]);
      }));

var read_getLongPollingResponse = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[/* messages */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg("messages", read__9, param);
                      }), json)];
      }));

var write_getLocationResponseGetLocationResponseItem = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "locationId", t[/* locationId */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "name", t[/* name */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, write_geoPosition, "position", t[/* position */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* int */5], "radius", t[/* radius */3]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]);
      }));

var read_getLocationResponseGetLocationResponseItem = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* int */4];
        var partial_arg$6 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* locationId */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("locationId", partial_arg, param);
                      }), json),
                /* name */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("name", partial_arg$2, param);
                      }), json),
                /* position */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$4("position", read_geoPosition, param);
                      }), json),
                /* radius */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$6("radius", partial_arg$5, param);
                      }), json)
              ];
      }));

var partial_arg$20 = Atdgen_codec_runtime.Encode[/* list */8];

function write__8(param) {
  return partial_arg$20(write_getLocationResponseGetLocationResponseItem, param);
}

var partial_arg$21 = Atdgen_codec_runtime.Decode[/* list */11];

function read__8(param) {
  return partial_arg$21(read_getLocationResponseGetLocationResponseItem, param);
}

var write_getConfigResponseKeycloakConfiguration = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "realm", t[/* realm */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "auth-server-url", t[/* authServerUrl */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "ssl-required", t[/* sslRequired */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "resource", t[/* resource */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* bool */6], "public-client", t[/* publicClient */4]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_getConfigResponseKeycloakConfiguration = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$6 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$7 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$8 = Atdgen_codec_runtime.Decode[/* bool */3];
        var partial_arg$9 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* realm */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("realm", partial_arg, param);
                      }), json),
                /* authServerUrl */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("auth-server-url", partial_arg$2, param);
                      }), json),
                /* sslRequired */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$5("ssl-required", partial_arg$4, param);
                      }), json),
                /* resource */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$7("resource", partial_arg$6, param);
                      }), json),
                /* publicClient */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$9("public-client", partial_arg$8, param);
                      }), json)
              ];
      }));

var write_getConfigResponse = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, write_json, "config", t[/* config */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, write_getConfigResponseKeycloakConfiguration, "keycloakConfiguration", t[/* keycloakConfiguration */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_getConfigResponse = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* config */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg("config", read_json, param);
                      }), json),
                /* keycloakConfiguration */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("keycloakConfiguration", read_getConfigResponseKeycloakConfiguration, param);
                      }), json)
              ];
      }));

var read_jsDateTime = read__1;

var write_jsDateTime = write__1;

var read_jsDate = read__2;

var write_jsDate = write__2;

var read_getTrainsResponse = read__12;

var write_getTrainsResponse = write__12;

var read_getTrackersResponse = read__11;

var write_getTrackersResponse = write__11;

var read_getTrackerMappingsResponse = read__10;

var write_getTrackerMappingsResponse = write__10;

var read_getLocationResponse = read__8;

var write_getLocationResponse = write__8;

exports.read_jsDateTime = read_jsDateTime;
exports.write_jsDateTime = write_jsDateTime;
exports.read_jsDate = read_jsDate;
exports.write_jsDate = write_jsDate;
exports.read_geoPosition = read_geoPosition;
exports.write_geoPosition = write_geoPosition;
exports.read_waypoint = read_waypoint;
exports.write_waypoint = write_waypoint;
exports.read_role = read_role;
exports.write_role = write_role;
exports.read_postTrackerMappingRequest = read_postTrackerMappingRequest;
exports.write_postTrackerMappingRequest = write_postTrackerMappingRequest;
exports.read_postLongPollingCreateSessionResponse = read_postLongPollingCreateSessionResponse;
exports.write_postLongPollingCreateSessionResponse = write_postLongPollingCreateSessionResponse;
exports.read_postLongPollingCreateSessionRequest = read_postLongPollingCreateSessionRequest;
exports.write_postLongPollingCreateSessionRequest = write_postLongPollingCreateSessionRequest;
exports.read_longPollingAction = read_longPollingAction;
exports.write_longPollingAction = write_longPollingAction;
exports.read_json = read_json;
exports.write_json = write_json;
exports.read_longPollingMessage = read_longPollingMessage;
exports.write_longPollingMessage = write_longPollingMessage;
exports.read_getTrainsResponseItem = read_getTrainsResponseItem;
exports.write_getTrainsResponseItem = write_getTrainsResponseItem;
exports.read_getTrainsResponse = read_getTrainsResponse;
exports.write_getTrainsResponse = write_getTrainsResponse;
exports.read_getTrackersResponseItem = read_getTrackersResponseItem;
exports.write_getTrackersResponseItem = write_getTrackersResponseItem;
exports.read_getTrackersResponse = read_getTrackersResponse;
exports.write_getTrackersResponse = write_getTrackersResponse;
exports.read_getTrackerMappingsResponseItem = read_getTrackerMappingsResponseItem;
exports.write_getTrackerMappingsResponseItem = write_getTrackerMappingsResponseItem;
exports.read_getTrackerMappingsResponse = read_getTrackerMappingsResponse;
exports.write_getTrackerMappingsResponse = write_getTrackerMappingsResponse;
exports.read_getLongPollingResponse = read_getLongPollingResponse;
exports.write_getLongPollingResponse = write_getLongPollingResponse;
exports.read_getLocationResponseGetLocationResponseItem = read_getLocationResponseGetLocationResponseItem;
exports.write_getLocationResponseGetLocationResponseItem = write_getLocationResponseGetLocationResponseItem;
exports.read_getLocationResponse = read_getLocationResponse;
exports.write_getLocationResponse = write_getLocationResponse;
exports.read_getConfigResponseKeycloakConfiguration = read_getConfigResponseKeycloakConfiguration;
exports.write_getConfigResponseKeycloakConfiguration = write_getConfigResponseKeycloakConfiguration;
exports.read_getConfigResponse = read_getConfigResponse;
exports.write_getConfigResponse = write_getConfigResponse;
/* write_geoPosition Not a pure module */
