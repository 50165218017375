// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Resizer = require("../components/Resizer.bs.js");
var WRouting = require("../navigation/WRouting.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ExportLink = require("../../common/ExportLink.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Api_Requests = require("../../common/api/Api_Requests.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var LabeledValue = require("../components/LabeledValue.bs.js");
var WTrackMapStore = require("../track/WTrackMapStore.bs.js");
var WTrackersFilter = require("./WTrackersFilter.bs.js");

function paramsEqual(a, b) {
  if (a[/* trackerId */0] === b[/* trackerId */0] && Time.Instant[/* equals */7](a[/* fromTime */1], b[/* fromTime */1])) {
    return Belt_Option.cmp(a[/* toTime */2], b[/* toTime */2], Time.Instant[/* compare */6]) === 0;
  } else {
    return false;
  }
}

var $$Map = WTrackMapStore.MakeTrackMap(/* module */[/* paramsEqual */paramsEqual]);

function onChangeDate(trackerId, fromTime, toTime) {
  return WRouting.RouterConfig[/* push */2](/* Track */Block.__(2, [/* TrackerRoute */Block.__(0, [
                    trackerId,
                    fromTime,
                    toTime
                  ])]));
}

function onSummary(self, summary) {
  return Curry._1(self[/* send */3], /* Summary */[summary]);
}

var component = ReasonReact.reducerComponent("WTrackerScreen");

function make(trackerId, fromTime, toTime, axios, authContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var fetcher = function (param) {
                return Api_Requests.getTrackOfTracker(param[/* trackerId */0], param[/* fromTime */1], param[/* toTime */2], axios, authContext[/* refreshToken */1]);
              };
              var params = /* record */[
                /* trackerId */trackerId,
                /* fromTime */fromTime,
                /* toTime */toTime
              ];
              var summary = self[/* state */1][/* summary */0];
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, WTrackersFilter.make(fromTime, toTime, (function (param, param$1) {
                                    return onChangeDate(trackerId, param, param$1);
                                  }), Js_primitive.some(React.createElement(React.Fragment, undefined, WTrackersFilter.spacer, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("gpx", trackerId, fromTime, toTime, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.gpx", "GPX", undefined, undefined, /* array */[]))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("csv", trackerId, fromTime, toTime, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.csv", "CSV", undefined, undefined, /* array */[]))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("json", trackerId, fromTime, toTime, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.json", "JSON", undefined, undefined, /* array */[]))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("xlsx", trackerId, fromTime, toTime, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.xlsx", "XLSX", undefined, undefined, /* array */[]))]))))), /* array */[])), React.createElement("div", {
                              className: LabeledValue.Styles[/* container */3]
                            }, ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerScreen.tracker", "Tracker", undefined, undefined, /* array */[])), trackerId, /* array */[])), summary !== undefined ? React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerScreen.waypoints", "Waypoints", undefined, undefined, /* array */[])), String(summary[/* numberOfWaypoints */0]), /* array */[]))) : null), ReasonReact.element(undefined, undefined, Resizer.make((function (width, height) {
                                    return ReasonReact.element(undefined, undefined, Curry._6($$Map[/* make */2], params, fetcher, (function (param) {
                                                      return Curry._1(self[/* send */3], /* Summary */[param]);
                                                    }), width, height, /* array */[]));
                                  }))));
            }),
          /* initialState */(function () {
              return /* record */[/* summary */undefined];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, _) {
              return /* Update */Block.__(0, [/* record */[/* summary */action[0]]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.$$Map = $$Map;
exports.onChangeDate = onChangeDate;
exports.onSummary = onSummary;
exports.component = component;
exports.make = make;
/* Map Not a pure module */
