// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Db = require("./Db.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Dexie = require("../bindings/Dexie.bs.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");

function decodeOrNone(key, decode, json) {
  try {
    return Js_primitive.some(Curry._1(decode, json));
  }
  catch (raw_exn){
    var exn = Js_exn.internalToOCamlException(raw_exn);
    if (exn[0] === Json_decode.DecodeError) {
      console.warn("Error decoding json for key '" + (key + ("': " + exn[1])));
      return undefined;
    } else {
      throw exn;
    }
  }
}

function get(key, decode) {
  return Repromise.Rejectable[/* catch */7]((function (error) {
                console.log("Error on Settings.get", error);
                return Repromise.resolved(undefined);
              }), Repromise.Rejectable[/* map */5]((function (jsonObj) {
                    return Belt_Option.flatMap(jsonObj, (function (param) {
                                  return decodeOrNone(key, decode, param);
                                }));
                  }), Repromise.Rejectable[/* fromJsPromise */10](Dexie.Table[/* get */0](Db.Tables[/* settings */1], key))));
}

function put(key, encode, value) {
  return Repromise.Rejectable[/* catch */7]((function (error) {
                console.log("Error on Settings.put", error);
                return Repromise.resolved(/* () */0);
              }), Repromise.Rejectable[/* map */5]((function () {
                    return /* () */0;
                  }), Repromise.Rejectable[/* fromJsPromise */10](Db.Tables[/* settings */1].put(Curry._1(encode, value), key))));
}

exports.decodeOrNone = decodeOrNone;
exports.get = get;
exports.put = put;
/* Db Not a pure module */
