// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Api_bs = require("./atd/Api_bs.bs.js");
var Api_Error = require("./api/Api_Error.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Api_Requests = require("./api/Api_Requests.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");

function SyncManager(Log) {
  var logResults = function (param) {
    var fetchResult = param[/* fetchResult */0];
    Curry._1(Log[/* info */1], "Sync finished.");
    if (fetchResult.tag) {
      return Curry._2(Log[/* error2 */7], "Error fetching incidents:", Api_Error.toString(fetchResult[0]));
    } else {
      return Curry._2(Log[/* info2 */5], "Fetch response:", Curry._1(Api_bs.write_getLocationResponse, fetchResult[0]));
    }
  };
  var SyncResults = /* module */[/* logResults */logResults];
  var component = ReasonReact.reducerComponent("SyncManager");
  var triggerSync = function (self) {
    return Curry._1(self[/* send */3], /* TriggerSync */0);
  };
  var scheduleNextSync = function (self) {
    self[/* state */1][/* timeoutId */4][0] = Js_primitive.some(setTimeout((function () {
                return Curry._1(self[/* send */3], /* TriggerSync */0);
              }), 60000));
    return /* () */0;
  };
  var cancelSyncTimer = function (self) {
    var match = self[/* state */1][/* timeoutId */4][0];
    if (match !== undefined) {
      clearTimeout(Js_primitive.valFromOption(match));
      self[/* state */1][/* timeoutId */4][0] = undefined;
      return /* () */0;
    } else {
      return /* () */0;
    }
  };
  var performSync = function (axios, authContext, self) {
    cancelSyncTimer(self);
    Curry._1(Log[/* info */1], "Sync starting.");
    return Repromise.wait((function (result) {
                  return Curry._1(self[/* send */3], /* SyncFinished */[result]);
                }), Repromise.map((function (result) {
                      return /* record */[/* fetchResult */result];
                    }), Api_Requests.getLocations(axios, authContext[/* refreshToken */1])));
  };
  var performMergeUpdatedLocations = function (state, updates) {
    var locations = Belt_MapString.mergeMany(state[/* locations */0], Belt_List.toArray(Belt_List.map(updates, (function ($$location) {
                    return /* tuple */[
                            $$location[/* locationId */0],
                            $$location
                          ];
                  }))));
    return /* record */[
            /* locations */locations,
            /* lastModified */state[/* lastModified */1],
            /* running */state[/* running */2],
            /* pending */state[/* pending */3],
            /* timeoutId */state[/* timeoutId */4]
          ];
  };
  var mergeUpdatedLocations = performMergeUpdatedLocations;
  var make = function (axios, authContext, children) {
    return /* record */[
            /* debugName */component[/* debugName */0],
            /* reactClassInternal */component[/* reactClassInternal */1],
            /* handedOffState */component[/* handedOffState */2],
            /* willReceiveProps */component[/* willReceiveProps */3],
            /* didMount */(function (self) {
                return Curry._1(self[/* send */3], /* TriggerSync */0);
              }),
            /* didUpdate */component[/* didUpdate */5],
            /* willUnmount */cancelSyncTimer,
            /* willUpdate */component[/* willUpdate */7],
            /* shouldUpdate */component[/* shouldUpdate */8],
            /* render */(function (self) {
                var locations = self[/* state */1][/* locations */0];
                return Curry._1(children, /* record */[
                            /* locations */locations,
                            /* triggerSync */(function () {
                                return Curry._1(self[/* send */3], /* TriggerSync */0);
                              })
                          ]);
              }),
            /* initialState */(function () {
                return /* record */[
                        /* locations */Belt_MapString.empty,
                        /* lastModified */undefined,
                        /* running */false,
                        /* pending */false,
                        /* timeoutId : record */[/* contents */undefined]
                      ];
              }),
            /* retainedProps */component[/* retainedProps */11],
            /* reducer */(function (action, state) {
                if (action) {
                  var fetchResult = action[0][/* fetchResult */0];
                  var newState = state;
                  if (!fetchResult.tag) {
                    newState = performMergeUpdatedLocations(newState, fetchResult[0]);
                  }
                  var init = newState;
                  newState = /* record */[
                    /* locations */init[/* locations */0],
                    /* lastModified */init[/* lastModified */1],
                    /* running */false,
                    /* pending */false,
                    /* timeoutId */init[/* timeoutId */4]
                  ];
                  var match = state[/* pending */3];
                  var action$1 = match ? triggerSync : scheduleNextSync;
                  return /* UpdateWithSideEffects */Block.__(2, [
                            newState,
                            action$1
                          ]);
                } else if (state[/* running */2]) {
                  return /* Update */Block.__(0, [/* record */[
                              /* locations */state[/* locations */0],
                              /* lastModified */state[/* lastModified */1],
                              /* running */state[/* running */2],
                              /* pending */true,
                              /* timeoutId */state[/* timeoutId */4]
                            ]]);
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            /* record */[
                              /* locations */state[/* locations */0],
                              /* lastModified */state[/* lastModified */1],
                              /* running */true,
                              /* pending */state[/* pending */3],
                              /* timeoutId */state[/* timeoutId */4]
                            ],
                            (function (param) {
                                return performSync(axios, authContext, param);
                              })
                          ]);
                }
              }),
            /* jsElementWrapped */component[/* jsElementWrapped */13]
          ];
  };
  return /* module */[
          /* SyncResults */SyncResults,
          /* component */component,
          /* triggerSync */triggerSync,
          /* scheduleNextSync */scheduleNextSync,
          /* cancelSyncTimer */cancelSyncTimer,
          /* performSync */performSync,
          /* performMergeUpdatedLocations */performMergeUpdatedLocations,
          /* mergeUpdatedLocations */mergeUpdatedLocations,
          /* make */make
        ];
}

var syncInterval = 60000;

exports.syncInterval = syncInterval;
exports.SyncManager = SyncManager;
/* Api_bs Not a pure module */
