// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReactResizeDetector = require("react-resize-detector");

function make($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, children) {
  var onResize = $staropt$star !== undefined ? $staropt$star : (function (_, _$1) {
        return /* () */0;
      });
  var handleWidth = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var handleHeight = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var skipOnMount = $staropt$star$3 !== undefined ? $staropt$star$3 : false;
  return ReasonReact.wrapJsForReason(ReactResizeDetector.default, {
              onResize: onResize,
              handleWidth: handleWidth,
              handleHeight: handleHeight,
              skipOnMount: skipOnMount
            }, children);
}

exports.make = make;
/* ReasonReact Not a pure module */
