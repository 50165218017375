// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Version = require("../../common/Version.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var WMenuModal = require("./WMenuModal.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WStyleUtils = require("../utils/WStyleUtils.bs.js");

var component = ReasonReact.statelessComponent("SupportModal");

function make(theme, open_, config, dispatch, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              var match = config[/* supportInfo */2];
              var email = match[/* email */1];
              var phoneNumber = match[/* phoneNumber */0];
              return ReasonReact.element(undefined, undefined, WMenuModal.make(open_, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.title", "Support", undefined, undefined, /* array */[])), dispatch, /* array */[React.createElement("div", undefined, React.createElement("div", {
                                        className: WMenuModal.Styles[/* subSection */1]
                                      }, React.createElement("h4", {
                                            className: WMenuModal.Styles[/* subHeading */0]
                                          }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.hotline.title", "Support-Hotline", undefined, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.hotlineText", "Our support hotline can be reached at: {supportTelLink}", {
                                                    supportTelLink: React.createElement("a", {
                                                          className: WStyleUtils.tintLinkClass(theme),
                                                          href: "tel:" + phoneNumber
                                                        }, phoneNumber)
                                                  }, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.emailText", "Our support email can be reached at: {supportEmailLink}", {
                                                    supportEmailLink: React.createElement("a", {
                                                          className: WStyleUtils.tintLinkClass(theme),
                                                          href: "mailto:" + email
                                                        }, email)
                                                  }, undefined, /* array */[]))), React.createElement("div", {
                                            className: WMenuModal.Styles[/* subSection */1]
                                          }, React.createElement("h4", {
                                                className: WMenuModal.Styles[/* subHeading */0]
                                              }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.appVersion", "App version", undefined, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.version", "Version: {version}", {
                                                        version: Version.versionInfo[/* version */0]
                                                      }, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.build", "Build: {build}", {
                                                        build: Version.versionInfo[/* build */1]
                                                      }, undefined, /* array */[])))), React.createElement("div", {
                                            className: WMenuModal.Styles[/* subSection */1]
                                          }, React.createElement("h4", {
                                                className: WMenuModal.Styles[/* subHeading */0]
                                              }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Support.browserInformation", "Browser Information", undefined, undefined, /* array */[]))), navigator.userAgent)))]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Styles = 0;

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* component Not a pure module */
