// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var Belt_SetString = require("bs-platform/lib/js/belt_SetString.js");
var KeycloakAdapter = require("../bindings/KeycloakAdapter.bs.js");

function userInfoFromToken(parsedToken) {
  var match = parsedToken.realm_access;
  return /* record */[
          /* username */parsedToken.preferred_username,
          /* email */Js_primitive.undefined_to_opt(parsedToken.email),
          /* firstName */Js_primitive.undefined_to_opt(parsedToken.given_name),
          /* lastName */Js_primitive.undefined_to_opt(parsedToken.family_name),
          /* roles */match !== undefined ? Belt_List.fromArray(match.roles) : /* [] */0
        ];
}

var component = ReasonReact.reducerComponent("WAuthManager");

function onTokenExpired(_, _$1) {
  console.log("Token expired");
  return /* () */0;
}

function makeAuthContext(self, keycloak) {
  var userInfo = userInfoFromToken(keycloak.tokenParsed);
  var refreshToken = function () {
    return Repromise.Rejectable[/* andThen */4]((function (refreshed) {
                  if (refreshed) {
                    console.log("Token refreshed, updating authContext");
                    Curry._1(self[/* send */3], /* TokenRefreshed */[makeAuthContext(self, keycloak)]);
                  }
                  return Repromise.Rejectable[/* resolved */2](keycloak.token);
                }), Repromise.Rejectable[/* fromJsPromise */10](KeycloakAdapter.Keycloak[/* updateToken */1](keycloak, 30)));
  };
  var logout = function () {
    keycloak.logout();
    return /* () */0;
  };
  return /* record */[
          /* userInfo */userInfo,
          /* refreshToken */refreshToken,
          /* logout */logout
        ];
}

function make(keycloak, children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return Curry._1(self[/* send */3], /* TokenRefreshed */[makeAuthContext(self, keycloak)]);
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1];
              if (match) {
                return Curry._1(children, match[0]);
              } else {
                return null;
              }
            }),
          /* initialState */(function () {
              return /* Initializing */0;
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, _) {
              return /* Update */Block.__(0, [/* Initialized */[action[0]]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

function hasAnyRole(userInfo, roles) {
  var userRoles = Belt_SetString.fromArray(Belt_List.toArray(userInfo[/* roles */4]));
  return !Belt_SetString.isEmpty(Belt_SetString.intersect(Belt_SetString.fromArray(Belt_List.toArray(roles)), userRoles));
}

function hasRole(userInfo, role) {
  var userRoles = Belt_SetString.fromArray(Belt_List.toArray(userInfo[/* roles */4]));
  return Belt_SetString.has(userRoles, role);
}

exports.userInfoFromToken = userInfoFromToken;
exports.component = component;
exports.onTokenExpired = onTokenExpired;
exports.makeAuthContext = makeAuthContext;
exports.make = make;
exports.hasAnyRole = hasAnyRole;
exports.hasRole = hasRole;
/* component Not a pure module */
