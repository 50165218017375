// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Resizer = require("../components/Resizer.bs.js");
var WRouting = require("../navigation/WRouting.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ExportLink = require("../../common/ExportLink.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Api_Requests = require("../../common/api/Api_Requests.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var LabeledValue = require("../components/LabeledValue.bs.js");
var WTrainsFilter = require("./WTrainsFilter.bs.js");
var WTrackMapStore = require("../track/WTrackMapStore.bs.js");
var FormattedInstant = require("../components/FormattedInstant.bs.js");

function paramsEqual(a, b) {
  if (a[/* trainNumber */0] === b[/* trainNumber */0]) {
    return Time.LocalDate[/* equals */8](a[/* operationDay */1], b[/* operationDay */1]);
  } else {
    return false;
  }
}

var $$Map = WTrackMapStore.MakeTrackMap(/* module */[/* paramsEqual */paramsEqual]);

function onChangeDate(trainNumber, operationDay) {
  return WRouting.RouterConfig[/* push */2](/* Track */Block.__(2, [/* TrainRoute */Block.__(1, [
                    trainNumber,
                    operationDay
                  ])]));
}

function onSummary(self, summary) {
  return Curry._1(self[/* send */3], /* Summary */[summary]);
}

var component = ReasonReact.reducerComponent("WTrainScreen");

function make(trainNumber, operationDay, axios, authContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var summary = self[/* state */1][/* summary */0];
              var tmp;
              if (summary !== undefined) {
                var summary$1 = summary;
                tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainScreen.tracker", "Tracker", undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, Curry._3(WRouting.Router[/* Link */1][/* make */1], /* Track */Block.__(2, [/* TrackerRoute */Block.__(0, [
                                            summary$1[/* trackerId */3],
                                            summary$1[/* firstGpsTime */1],
                                            summary$1[/* lastGpsTime */2]
                                          ])]), undefined, /* array */[summary$1[/* trackerId */3]])), /* array */[])), ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainScreen.waypoints", "Number of Waypoints", undefined, undefined, /* array */[])), String(summary$1[/* numberOfWaypoints */0]), /* array */[])), ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainScreen.from", "First waypoint at", undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, FormattedInstant.make(summary$1[/* firstGpsTime */1], /* array */[])), /* array */[])), ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainScreen.to", "Last waypoint at", undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, FormattedInstant.make(summary$1[/* lastGpsTime */2], /* array */[])), /* array */[])));
              } else {
                tmp = null;
              }
              var fetcher = function (param) {
                return Api_Requests.getTrackOfTrain(param[/* trainNumber */0], param[/* operationDay */1], axios, authContext[/* refreshToken */1]);
              };
              var params = /* record */[
                /* trainNumber */trainNumber,
                /* operationDay */operationDay
              ];
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, WTrainsFilter.make(operationDay, (function (param) {
                                    return onChangeDate(trainNumber, param);
                                  }), Js_primitive.some(React.createElement(React.Fragment, undefined, WTrainsFilter.spacer, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("gpx", trainNumber, operationDay, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.gpx", "GPX", undefined, undefined, /* array */[]))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("csv", trainNumber, operationDay, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.csv", "CSV", undefined, undefined, /* array */[]))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("json", trainNumber, operationDay, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.json", "JSON", undefined, undefined, /* array */[]))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("xlsx", trainNumber, operationDay, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.xlsx", "XLSX", undefined, undefined, /* array */[]))]))))), /* array */[])), React.createElement("div", {
                              className: LabeledValue.Styles[/* container */3]
                            }, ReasonReact.element(undefined, undefined, LabeledValue.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainScreen.train", "Train", undefined, undefined, /* array */[])), trainNumber, /* array */[])), tmp), ReasonReact.element(undefined, undefined, Resizer.make((function (width, height) {
                                    return ReasonReact.element(undefined, undefined, Curry._6($$Map[/* make */2], params, fetcher, (function (param) {
                                                      return Curry._1(self[/* send */3], /* Summary */[param]);
                                                    }), width, height, /* array */[]));
                                  }))));
            }),
          /* initialState */(function () {
              return /* record */[/* summary */undefined];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, _) {
              return /* Update */Block.__(0, [/* record */[/* summary */action[0]]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.$$Map = $$Map;
exports.onChangeDate = onChangeDate;
exports.onSummary = onSummary;
exports.component = component;
exports.make = make;
/* Map Not a pure module */
