// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var WRouting = require("./WRouting.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WNavbar_Styles = require("./WNavbar_Styles.bs.js");

var component = ReasonReact.statelessComponent("WNavbarInternalLinkItem");

function make(title, _, _$1) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Curry._3(WRouting.Router[/* Link */1][/* make */1], /* Main */0, WNavbar_Styles.item + (" " + WNavbar_Styles.activeItem), /* array */[title]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Router = 0;

exports.component = component;
exports.Router = Router;
exports.make = make;
/* component Not a pure module */
