// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Styles = require("../css/Styles.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var DatePicker = require("../components/DatePicker.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");

var component = ReasonReact.statelessComponent("WTrainsFilter");

function make(operationDay, onChangeDate, $staropt$star, _) {
  var extraItem = $staropt$star !== undefined ? Js_primitive.valFromOption($staropt$star) : null;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: Styles.Toolbar[/* container */0]
                        }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsFilter.operationDay", "Date", undefined, undefined, /* array */[]))), ReasonReact.element(undefined, undefined, DatePicker.make((function (date) {
                                    if (date !== undefined) {
                                      return Curry._1(onChangeDate, date);
                                    } else {
                                      return /* () */0;
                                    }
                                  }), operationDay, Time.LocalDate[/* make */3](/* () */0), undefined, Styles.Toolbar[/* picker */2], true, undefined, /* array */[])), extraItem);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var spacer = React.createElement("div", {
      className: Styles.Toolbar[/* spacer */1]
    });

exports.component = component;
exports.make = make;
exports.spacer = spacer;
/* component Not a pure module */
