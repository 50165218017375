// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../types/Time.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");

var wrap = Time.LocalDate[/* fromString */2];

var unwrap = Time.LocalDate[/* toISOString */5];

var JsDate = /* module */[
  /* wrap */wrap,
  /* unwrap */unwrap
];

var wrap$1 = Time.Instant[/* fromString */3];

var unwrap$1 = Time.Instant[/* toISOString */4];

var JsDateTime = /* module */[
  /* wrap */wrap$1,
  /* unwrap */unwrap$1
];

function write_json(j) {
  return j;
}

function read_json(j) {
  return j;
}

var JsJson = /* module */[
  /* write_json */write_json,
  /* read_json */read_json
];

function wrap$2(l) {
  return Belt_MapString.fromArray(Belt_List.toArray(l));
}

function unwrap$2(stringMap) {
  return Belt_List.fromArray(Belt_MapString.toArray(stringMap));
}

var StringMap = /* module */[
  /* wrap */wrap$2,
  /* unwrap */unwrap$2
];

exports.JsDate = JsDate;
exports.JsDateTime = JsDateTime;
exports.JsJson = JsJson;
exports.StringMap = StringMap;
/* Time Not a pure module */
