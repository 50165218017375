// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../types/Time.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Api_bs = require("../atd/Api_bs.bs.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var Api_Common = require("./Api_Common.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function getConfig(axios) {
  return Api_Common.decodeResult(Api_bs.read_getConfigResponse, Repromise.Rejectable[/* fromJsPromise */10](axios.get("/config")));
}

function getTrains(operationDay, axios, getToken) {
  return Api_Common.decodeResult(Api_bs.read_getTrainsResponse, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.get("/trains/" + Time.LocalDate[/* toISOString */5](operationDay), Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function getTrackers(fromTime, toTime, axios, getToken) {
  var fromTimeString = Time.Instant[/* toISOString */4](fromTime);
  var toTimeString = Belt_Option.map(toTime, Time.Instant[/* toISOString */4]);
  return Api_Common.decodeResult(Api_bs.read_getTrackersResponse, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.get("/trackers?from=" + (fromTimeString + (
                                      toTimeString !== undefined ? "&to=" + toTimeString : ""
                                    )), Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function getTrackOfTracker(trackerId, fromTime, toTime, axios, getToken) {
  var fromTimeString = Time.Instant[/* toISOString */4](fromTime);
  var toTimeString = Belt_Option.map(toTime, Time.Instant[/* toISOString */4]);
  var decode = function (param) {
    return Json_decode.list(Api_bs.read_waypoint, param);
  };
  return Api_Common.decodeResult(decode, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.get("/trackers/" + (trackerId + ("/track.json?from=" + (fromTimeString + (
                                          toTimeString !== undefined ? "&to=" + toTimeString : ""
                                        )))), Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function getTrackerMappings(axios, getToken) {
  return Api_Common.decodeResult(Api_bs.read_getTrackerMappingsResponse, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.get("/trackers/mappings", Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function postTrackerMapping(request, axios, getToken) {
  return Api_Common.noResult(Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.post("/trackers/mappings", Curry._1(Api_bs.write_postTrackerMappingRequest, request), Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function deleteTrackerMapping(trackerId, axios, getToken) {
  return Api_Common.noResult(Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.delete("/trackers/" + (trackerId + "/mappings"), Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function getTrackOfTrain(trainNumber, operationDay, axios, getToken) {
  var operationDayString = Time.LocalDate[/* toISOString */5](operationDay);
  var decode = function (param) {
    return Json_decode.list(Api_bs.read_waypoint, param);
  };
  return Api_Common.decodeResult(decode, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.get("/trains/" + (operationDayString + ("/" + (trainNumber + "/track.json"))), Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function getLocations(axios, getToken) {
  return Api_Common.decodeResult(Api_bs.read_getLocationResponse, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.get("/locations", Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function longPollingCreateSession(axios, getToken) {
  return Api_Common.decodeResult(Api_bs.read_postLongPollingCreateSessionResponse, Repromise.Rejectable[/* andThen */4]((function (token) {
                    return Repromise.Rejectable[/* fromJsPromise */10](axios.post("/xhr/create", { }, Api_Common.getAxiosConfig(undefined, undefined, token)));
                  }), Curry._1(getToken, /* () */0)));
}

function longPollingPoll(sessionId, axios) {
  return Api_Common.decodeResult(Api_bs.read_getLongPollingResponse, Repromise.Rejectable[/* fromJsPromise */10](axios.get("/xhr/" + sessionId)));
}

exports.getConfig = getConfig;
exports.getTrains = getTrains;
exports.getTrackers = getTrackers;
exports.getTrackOfTracker = getTrackOfTracker;
exports.getTrackerMappings = getTrackerMappings;
exports.postTrackerMapping = postTrackerMapping;
exports.deleteTrackerMapping = deleteTrackerMapping;
exports.getTrackOfTrain = getTrackOfTrain;
exports.getLocations = getLocations;
exports.longPollingCreateSession = longPollingCreateSession;
exports.longPollingPoll = longPollingPoll;
/* Time Not a pure module */
