// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Settings = require("./db/Settings.bs.js");
var Api_Error = require("../common/api/Api_Error.bs.js");
var Common_bs = require("../common/atd/Common_bs.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var Api_Common = require("../common/api/Api_Common.bs.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var StartupJs = require("./startup.js");
var Api_Requests = require("../common/api/Api_Requests.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var KeycloakAdapter = require("./bindings/KeycloakAdapter.bs.js");
var WStartupKeycloak = require("./WStartupKeycloak.bs.js");
var WMissingConfigView = require("./navigation/WMissingConfigView.bs.js");
var WKeycloakInitializationErrorView = require("./navigation/WKeycloakInitializationErrorView.bs.js");

var configKey = "config";

var configState = /* record */[/* contents */undefined];

function doFetchConfig(axios) {
  return Repromise.andThen((function (result) {
                if (result.tag) {
                  console.log("Error fetching config", Api_Error.toString(result[0]));
                  return Repromise.resolved(/* () */0);
                } else {
                  var getConfigResponse = result[0];
                  try {
                    var config = Curry._1(Common_bs.read_config, getConfigResponse[/* config */0]);
                    var keycloakConfiguration = getConfigResponse[/* keycloakConfiguration */1];
                    var cState = /* record */[
                      /* config */config,
                      /* keycloakConfiguration */keycloakConfiguration
                    ];
                    configState[0] = cState;
                    return Repromise.map((function () {
                                  return /* () */0;
                                }), Settings.put(configKey, Common_bs.write_configState, cState));
                  }
                  catch (raw_exn){
                    var exn = Js_exn.internalToOCamlException(raw_exn);
                    if (exn[0] === Json_decode.DecodeError) {
                      console.warn("Error decoding config", exn[1]);
                      return Repromise.resolved(/* () */0);
                    } else {
                      throw exn;
                    }
                  }
                }
              }), Api_Requests.getConfig(axios));
}

function configureKeycloak(keycloakConfiguration) {
  return KeycloakAdapter.KeycloakOpenIdConfig[/* make */0](keycloakConfiguration[/* authServerUrl */1], keycloakConfiguration[/* realm */0], keycloakConfiguration[/* resource */3]);
}

function startup() {
  var axios = Api_Common.makeAxios("");
  return Repromise.wait((function (keycloak) {
                var match = configState[0];
                if (match !== undefined) {
                  if (keycloak !== undefined) {
                    var match$1 = match;
                    var appContext_001 = /* config */match$1[/* config */0];
                    var appContext_002 = /* keycloakConfig */configureKeycloak(match$1[/* keycloakConfiguration */1]);
                    var appContext_003 = /* keycloak */Js_primitive.valFromOption(keycloak);
                    var appContext = /* record */[
                      /* axios */axios,
                      appContext_001,
                      appContext_002,
                      appContext_003
                    ];
                    StartupJs.startApplication(appContext);
                    return /* () */0;
                  } else {
                    return ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, ReactIntl.IntlProvider[/* make */0](undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, WKeycloakInitializationErrorView.make(/* array */[]))])), "root");
                  }
                } else {
                  return ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, ReactIntl.IntlProvider[/* make */0](undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, WMissingConfigView.make(/* array */[]))])), "root");
                }
              }), Repromise.andThen((function () {
                    var match = configState[0];
                    if (match !== undefined) {
                      return WStartupKeycloak.initKeycloak(configureKeycloak(match[/* keycloakConfiguration */1]));
                    } else {
                      return Repromise.resolved(undefined);
                    }
                  }), Repromise.andThen((function (restoredState) {
                        configState[0] = restoredState;
                        return doFetchConfig(axios);
                      }), Settings.get(configKey, Common_bs.read_configState))));
}

exports.configKey = configKey;
exports.configState = configState;
exports.doFetchConfig = doFetchConfig;
exports.configureKeycloak = configureKeycloak;
exports.startup = startup;
/* Settings Not a pure module */
