// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Version = require("../../common/Version.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var WMenuItem = require("./WMenuItem.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WStyleUtils = require("../utils/WStyleUtils.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var WSupportModal = require("./WSupportModal.bs.js");
var WCopyrightModal = require("./WCopyrightModal.bs.js");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js");
var MaterialUi_Popover = require("@jsiebern/bs-material-ui/src/MaterialUi_Popover.bs.js");

var menuPadding = Css.px(15);

var header = Css.style(/* :: */[
      Css.padding(menuPadding),
      /* :: */[
        Css.paddingLeft(Css.px(7)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.justifyContent(/* flexEnd */924268066),
            /* :: */[
              Css.borderBottom(Css.px(1), /* solid */12956715, /* `hex */[
                    5194459,
                    "e7e7e7"
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var menu = Css.style(/* :: */[
      Css.backgroundColor(Css.white),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* :: */[
              Css.color(/* `hex */[
                    5194459,
                    "333"
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var content = Css.style(/* :: */[
      Css.padding(menuPadding),
      /* :: */[
        Css.paddingRight(Css.px(5)),
        /* :: */[
          Css.borderBottom(Css.px(1), /* solid */12956715, /* `hex */[
                5194459,
                "e7e7e7"
              ]),
          /* [] */0
        ]
      ]
    ]);

var version = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.color(/* `hex */[
                5194459,
                "959595"
              ]),
          /* [] */0
        ]
      ]
    ]);

var footer = Css.style(/* :: */[
      Css.padding2(Css.px(0), menuPadding),
      /* :: */[
        Css.margin2(Css.px(5), Css.px(0)),
        /* [] */0
      ]
    ]);

var hotlineLink = Css.style(/* :: */[
      Css.textDecoration(/* underline */131142924),
      /* [] */0
    ]);

var Styles = /* module */[
  /* menuPadding */menuPadding,
  /* header */header,
  /* menu */menu,
  /* content */content,
  /* version */version,
  /* footer */footer,
  /* hotlineLink */hotlineLink
];

var component = ReasonReact.reducerComponent("Menu");

var anchorOrigin = MaterialUi_Popover.AnchorOrigin[/* make */0](/* `Enum */[
      770676513,
      /* Right */-57574468
    ], /* `Enum */[
      770676513,
      /* Bottom */437082891
    ], /* () */0);

var transformOrigin = MaterialUi_Popover.TransformOrigin[/* make */0](/* `Enum */[
      770676513,
      /* Right */-57574468
    ], /* `Enum */[
      770676513,
      /* Top */4202101
    ], /* () */0);

function renderPopover(self, onLogout, open_, element, config, theme, onClose) {
  var handleModalLinkClicked = function (modal, evt) {
    evt.preventDefault();
    Curry._1(onClose, /* () */0);
    return Curry._1(self[/* send */3], /* ShowModal */[modal]);
  };
  var phoneNumber = config[/* supportInfo */2][/* phoneNumber */0];
  return ReasonReact.element(undefined, undefined, MaterialUi_Popover.make(undefined, element, Js_primitive.some(anchorOrigin), undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                    return Curry._1(onClose, /* () */0);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, open_, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[React.createElement("div", {
                        className: menu
                      }, React.createElement("div", {
                            className: header
                          }, ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                                      return Curry._1(onLogout, /* () */0);
                                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Menu.logout", "Logout", undefined, undefined, /* array */[]))]))), React.createElement("div", {
                            className: content
                          }, ReasonReact.element(undefined, undefined, WMenuItem.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Menu.support", "Support", undefined, undefined, /* array */[])), Js_primitive.some(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Menu.supportText", "Help & Support", undefined, undefined, /* array */[]))), WStyleUtils.tintLinkClass(theme), (function (param) {
                                      return handleModalLinkClicked(/* Support */0, param);
                                    }), /* array */[])), ReasonReact.element(undefined, undefined, WMenuItem.make(ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Menu.copyright", "Copyright", undefined, undefined, /* array */[])), undefined, WStyleUtils.tintLinkClass(theme), (function (param) {
                                      return handleModalLinkClicked(/* Copyright */1, param);
                                    }), /* array */[])), React.createElement("div", {
                                className: version
                              }, Version.versionInfo[/* version */0] + (" (" + (Version.versionInfo[/* build */1] + ")")))), React.createElement("div", {
                            className: footer
                          }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Menu.problems", "Problems with the Application?", undefined, undefined, /* array */[])), React.createElement("br", undefined), ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Menu.callSupport", "Call our support hotline: {phoneNumberLink}", {
                                    phoneNumberLink: React.createElement("a", {
                                          className: WStyleUtils.tintLinkClass(theme),
                                          href: "tel:" + phoneNumber
                                        }, phoneNumber)
                                  }, undefined, /* array */[]))))]));
}

function make(open_, config, anchorElement, onClose, onLogout, theme, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, WCopyrightModal.make(theme, self[/* state */1][/* modal */0] === /* Copyright */1, (function (action) {
                                    return Curry._1(self[/* send */3], action);
                                  }), /* array */[])), ReasonReact.element(undefined, undefined, WSupportModal.make(theme, self[/* state */1][/* modal */0] === /* Support */0, config, (function (action) {
                                    return Curry._1(self[/* send */3], action);
                                  }), /* array */[])), anchorElement !== undefined ? renderPopover(self, onLogout, open_, anchorElement, config, theme, onClose) : null);
            }),
          /* initialState */(function () {
              return /* record */[/* modal : NoModal */2];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, _) {
              if (action) {
                return /* Update */Block.__(0, [/* record */[/* modal */action[0]]]);
              } else {
                return /* Update */Block.__(0, [/* record */[/* modal : NoModal */2]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Styles = Styles;
exports.component = component;
exports.anchorOrigin = anchorOrigin;
exports.transformOrigin = transformOrigin;
exports.renderPopover = renderPopover;
exports.make = make;
/* menuPadding Not a pure module */
