// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Colors = require("../../common/Colors.bs.js");

var navbar = Css.style(/* :: */[
      Css.minHeight(Css.px(50)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors.navbarBackground)),
        /* :: */[
          Css.color(Css.white),
          /* :: */[
            Css.fontSize(Css.px(16)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.justifyContent(/* spaceBetween */516682146),
                /* :: */[
                  Css.overflowX(/* hidden */-862584982),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var left = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.marginLeft(Css.px(2)),
          /* :: */[
            Css.selector(" > a, > a:visited, > a:hover", /* :: */[
                  Css.textDecoration(/* none */-922086728),
                  /* :: */[
                    Css.color(Css.white),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var right = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.minWidth(Css.px(0)),
        /* [] */0
      ]
    ]);

var item = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.padding(Css.px(15)),
            /* :: */[
              Css.color(Css.white),
              /* :: */[
                Css.textDecoration(/* none */-922086728),
                /* :: */[
                  Css.boxSizing(/* borderBox */9307263),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var activeItem = Css.style(/* :: */[
      Css.padding2(Css.px(13), Css.px(15)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors.activeNavItemColor)),
        /* :: */[
          Css.borderTopStyle(/* solid */12956715),
          /* :: */[
            Css.borderTopColor(Css.hex(Colors.activeNavItemColor)),
            /* :: */[
              Css.borderBottom(Css.px(3), /* solid */12956715, Css.hex(Colors.activeNavItemIndicator)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

exports.navbar = navbar;
exports.left = left;
exports.right = right;
exports.item = item;
exports.activeItem = activeItem;
/* navbar Not a pure module */
