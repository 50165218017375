// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Styles = require("../css/Styles.bs.js");
var WRouting = require("../navigation/WRouting.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ExportLink = require("../../common/ExportLink.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var RemoteStore = require("../components/RemoteStore.bs.js");
var Utils_React = require("../../common/utils/Utils_React.bs.js");
var Api_Requests = require("../../common/api/Api_Requests.bs.js");
var WTrackersFilter = require("./WTrackersFilter.bs.js");

function paramsEqual(a, b) {
  if (Time.Instant[/* equals */7](a[/* fromTime */0], b[/* fromTime */0])) {
    return Belt_Option.cmp(a[/* toTime */1], b[/* toTime */1], Time.Instant[/* compare */6]) === 0;
  } else {
    return false;
  }
}

function compareItems(a, b) {
  return $$String.compare(a[/* trackerId */0], b[/* trackerId */0]);
}

var TrackersRemoteStore = RemoteStore.MakeRemoteStore(/* module */[
      /* paramsEqual */paramsEqual,
      /* compareItems */compareItems,
      /* componentName */"WTrackersStore"
    ]);

function onChangeDate(fromTime, toTime) {
  return WRouting.RouterConfig[/* push */2](/* Trackers */Block.__(0, [
                fromTime,
                toTime
              ]));
}

function makeTracker(tracker) {
  return React.createElement("tr", {
              key: tracker[/* trackerId */0],
              onClick: (function () {
                  return WRouting.RouterConfig[/* push */2](/* Track */Block.__(2, [/* TrackerRoute */Block.__(0, [
                                    tracker[/* trackerId */0],
                                    tracker[/* firstGpsTime */2],
                                    tracker[/* lastGpsTime */3]
                                  ])]));
                })
            }, React.createElement("td", undefined, tracker[/* trackerId */0]), React.createElement("td", undefined, tracker[/* vehicleId */1]), React.createElement("td", undefined, Time.Instant[/* toISOString */4](tracker[/* firstGpsTime */2])), React.createElement("td", undefined, Time.Instant[/* toISOString */4](tracker[/* lastGpsTime */3])), React.createElement("td", undefined, String(tracker[/* numberOfWaypoints */4])), React.createElement("td", undefined, ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("gpx", tracker[/* trackerId */0], tracker[/* firstGpsTime */2], tracker[/* lastGpsTime */3], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.gpx", "GPX", undefined, undefined, /* array */[]))])), " ", ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("csv", tracker[/* trackerId */0], tracker[/* firstGpsTime */2], tracker[/* lastGpsTime */3], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.csv", "CSV", undefined, undefined, /* array */[]))])), " ", ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("json", tracker[/* trackerId */0], tracker[/* firstGpsTime */2], tracker[/* lastGpsTime */3], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.json", "JSON", undefined, undefined, /* array */[]))])), " ", ReasonReact.element(undefined, undefined, ExportLink.Tracker[/* make */1]("xlsx", tracker[/* trackerId */0], tracker[/* firstGpsTime */2], tracker[/* lastGpsTime */3], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.xlsx", "XLSX", undefined, undefined, /* array */[]))]))));
}

var component = ReasonReact.statelessComponent("WTrackersScreen");

function make(fromTime, toTime, axios, authContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              var params = /* record */[
                /* fromTime */fromTime,
                /* toTime */toTime
              ];
              var fetcher = function (param) {
                return Api_Requests.getTrackers(param[/* fromTime */0], param[/* toTime */1], axios, authContext[/* refreshToken */1]);
              };
              var loadingElement = React.createElement("div", {
                    className: Styles.DataView[/* message */1]
                  }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.loading", "Loading", undefined, undefined, /* array */[])));
              var noItemsElement = React.createElement("div", {
                    className: Styles.DataView[/* message */1]
                  }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.noTrackers", "No trackers found", undefined, undefined, /* array */[])));
              var errorElement = function () {
                return React.createElement("div", {
                            className: Styles.DataView[/* message */1]
                          }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.error", "Error on fetching trackers", undefined, undefined, /* array */[])));
              };
              var listElement = function (trackers) {
                return React.createElement("div", {
                            className: Styles.DataView[/* table */2]
                          }, React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.tracker", "Tracker", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.vehicle", "Vehicle", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.start", "Start", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.end", "End", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.numberOfWaypoints", "# Waypoints", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackersScreen.download", "Download", undefined, undefined, /* array */[]))))), React.createElement("tbody", undefined, Utils_React.mapListToReactElements(trackers, makeTracker))));
              };
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, WTrackersFilter.make(fromTime, toTime, onChangeDate, undefined, /* array */[])), ReasonReact.element(undefined, undefined, Curry._7(TrackersRemoteStore[/* make */2], params, fetcher, loadingElement, errorElement, listElement, noItemsElement, /* array */[])));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.TrackersRemoteStore = TrackersRemoteStore;
exports.onChangeDate = onChangeDate;
exports.makeTracker = makeTracker;
exports.component = component;
exports.make = make;
/* TrackersRemoteStore Not a pure module */
