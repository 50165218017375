// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var WRouting = require("./WRouting.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var appName = Css.style(/* :: */[
      Css.paddingLeft(Css.px(6)),
      /* :: */[
        Css.paddingRight(Css.px(15)),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* [] */0
        ]
      ]
    ]);

var homeLink = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var Styles = /* module */[
  /* appName */appName,
  /* homeLink */homeLink
];

var component = ReasonReact.statelessComponent("WNavbarAppItem");

function make(appName$1, appIconUrl, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._3(WRouting.Router[/* Link */1][/* make */1], /* Main */0, homeLink, /* array */[
                                  React.createElement("img", {
                                        src: appIconUrl,
                                        width: "48px"
                                      }),
                                  React.createElement("div", {
                                        className: appName
                                      }, appName$1)
                                ])));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Router = 0;

exports.Router = Router;
exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* appName Not a pure module */
