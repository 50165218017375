import { detect } from 'detect-browser';
import areIntlLocalesSupported from 'intl-locales-supported';

const browser = detect();

const localesMyAppSupports = ['en', 'de', 'fr'];

// Check if Intl is there. (Do not use IE's/Edge's broken Intl.)
if (global.Intl && browser && browser.name !== 'ie' && browser.name !== 'edge') {
  // Determine if the built-in `Intl` has the locale data we need.
  if (areIntlLocalesSupported(localesMyAppSupports)) {
    // console.info('Intl is present');
  } else {
    // console.info('Intl is present, but locales are unsupported => polyfilling');

    // `Intl` exists, but it doesn't have the data we need, so load the
    // polyfill and patch the constructors we need with the polyfill's.
    const IntlPolyfill = require('intl');

    global.Intl.NumberFormat = IntlPolyfill.NumberFormat;
    global.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
  }
} else {
  // console.info('Intl is not present => polyfilling');

  // No `Intl`, so use and load the polyfill.
  global.Intl = require('intl');
  require('intl/locale-data/jsonp/en.js');
  require('intl/locale-data/jsonp/de.js');
  require('intl/locale-data/jsonp/fr.js');
}
