import React from 'react';
import DatePicker from 'react-date-picker';

export default function OurDatePicker(props) {
  return (
    <DatePicker
      showLeadingZeros={true}
      clearIcon={props.required ? null : DatePicker.defaultProps.clearIcon}
      returnValue="start"
      {...props}
    />
  );
}
