import geolib from 'geolib';

const calculateCenterAndZoom = (positions, height) => {
  const coords = positions.map(x => ({ longitude: x.lon, latitude: x.lat }));
  const center = geolib.getCenterOfBounds(coords);
  const orderedCoords = geolib.orderByDistance(center, coords);
  const maxDistance = orderedCoords[orderedCoords.length - 1].distance;
  const zoom = Math.log((1000 * 128 * height) / maxDistance) / Math.log(2) - 1;
  return { center, zoom };
};

export default calculateCenterAndZoom;
