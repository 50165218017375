// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';


var Roles = /* module */[/* user */"ROLE_TRAIN_TRACKER_USER"];

function getDisplayName(userInfo) {
  var match = userInfo[/* firstName */2];
  var match$1 = userInfo[/* lastName */3];
  if (match !== undefined) {
    var firstName = match;
    if (match$1 !== undefined) {
      return firstName + (" " + match$1);
    } else {
      return firstName;
    }
  } else if (match$1 !== undefined) {
    return match$1;
  } else {
    return userInfo[/* username */0];
  }
}

exports.Roles = Roles;
exports.getDisplayName = getDisplayName;
/* No side effect */
