// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var WIcon = require("../WIcon.bs.js");
var React = require("react");
var Colors = require("../../common/Colors.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var menuButtonRules_000 = Css.margin2(Css.px(1), Css.px(0));

var menuButtonRules_001 = /* :: */[
  Css.padding2(Css.px(0), Css.px(15)),
  /* :: */[
    Css.display(/* flex */-1010954439),
    /* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.cursor(/* pointer */-786317123),
        /* :: */[
          Css.minWidth(Css.px(25)),
          /* [] */0
        ]
      ]
    ]
  ]
];

var menuButtonRules = /* :: */[
  menuButtonRules_000,
  menuButtonRules_001
];

var menuButtonOpenRules_000 = Css.backgroundColor(/* `hex */[
      5194459,
      "080808"
    ]);

var menuButtonOpenRules = /* :: */[
  menuButtonOpenRules_000,
  /* [] */0
];

var menuButton = Css.style(menuButtonRules);

var menuButtonOpen = Css.style(Css.merge(/* :: */[
          menuButtonRules,
          /* :: */[
            menuButtonOpenRules,
            /* [] */0
          ]
        ]));

var hamburgerIcon = Css.style(/* :: */[
      Css.fontSize(Css.px(25)),
      /* :: */[
        Css.marginTop(Css.px(4)),
        /* [] */0
      ]
    ]);

var userInfo = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.minWidth(Css.px(25)),
          /* [] */0
        ]
      ]
    ]);

var tenantAndUserName = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.2
                ]),
            /* :: */[
              Css.marginBottom(Css.px(3)),
              /* :: */[
                Css.minWidth(Css.px(0)),
                /* :: */[
                  Css.overflowX(/* hidden */-862584982),
                  /* :: */[
                    Css.alignItems(/* flexEnd */924268066),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var tenantName = Css.style(/* :: */[
      Css.color(Css.hex(Colors.greyText)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.minWidth(Css.px(0)),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* :: */[
              Css.overflowX(/* hidden */-862584982),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var userName = Css.style(/* :: */[
      Css.fontSize(Css.px(19)),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.minWidth(Css.px(0)),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* :: */[
              Css.overflowX(/* hidden */-862584982),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var userIcon = Css.style(/* :: */[
      Css.color(Css.hex(Colors.greyText)),
      /* :: */[
        Css.fontSize(Css.px(35)),
        /* :: */[
          Css.padding4(Css.px(2), Css.px(4), Css.px(0), Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

var Styles = /* module */[
  /* menuButtonRules */menuButtonRules,
  /* menuButtonOpenRules */menuButtonOpenRules,
  /* menuButton */menuButton,
  /* menuButtonOpen */menuButtonOpen,
  /* hamburgerIcon */hamburgerIcon,
  /* userInfo */userInfo,
  /* tenantAndUserName */tenantAndUserName,
  /* tenantName */tenantName,
  /* userName */userName,
  /* userIcon */userIcon
];

function renderUserInfo(userDisplayName, tenantDisplayName) {
  return React.createElement("div", {
              className: userInfo
            }, React.createElement("div", {
                  className: tenantAndUserName
                }, React.createElement("span", {
                      className: tenantName
                    }, tenantDisplayName !== undefined ? tenantDisplayName : ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WMenuButton.loggedInAs", "Logged in as", undefined, undefined, /* array */[]))), React.createElement("span", {
                      className: userName
                    }, userDisplayName)), React.createElement("span", {
                  className: userIcon
                }, ReasonReact.element(undefined, undefined, WIcon.make(/* user */-844262837, /* array */[]))), ReasonReact.element(undefined, undefined, WIcon.make(/* drawer */-539587215, /* array */[])));
}

var component = ReasonReact.statelessComponent("WMenuButton");

function make(userDisplayName, tenantDisplayName, menuOpen, onClick, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: menuOpen ? menuButtonOpen : menuButton,
                          onClick: onClick
                        }, renderUserInfo(userDisplayName, tenantDisplayName));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Styles = Styles;
exports.renderUserInfo = renderUserInfo;
exports.component = component;
exports.make = make;
/* menuButtonRules Not a pure module */
