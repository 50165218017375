// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Js_primitive = require("bs-platform/lib/js/js_primitive.js");

var Collection = /* module */[];

var WhereClause = /* module */[];

function get(table, key) {
  return table.get(key).then((function (nullable) {
                return Promise.resolve((nullable == null) ? undefined : Js_primitive.some(nullable));
              }));
}

function getBlob(table, key) {
  return table.get(key).then((function (nullable) {
                return Promise.resolve((nullable == null) ? undefined : Js_primitive.some(nullable));
              }));
}

var Table = /* module */[
  /* get */get,
  /* getBlob */getBlob
];

var Transaction = /* module */[];

var Version = /* module */[];

exports.Collection = Collection;
exports.WhereClause = WhereClause;
exports.Table = Table;
exports.Transaction = Transaction;
exports.Version = Version;
/* No side effect */
