// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("WMenuItem");

var menuLink = Css.style(/* :: */[
      Css.fontSize(Css.px(18)),
      /* :: */[
        Css.textDecoration(/* underline */131142924),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.padding2(Css.px(4), Css.px(0)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* [] */0
        ]
      ]
    ]);

var Styles = /* module */[
  /* menuLink */menuLink,
  /* menuItem */menuItem
];

function make(title, subtitle, linkClass, onClick, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: menuItem
                        }, React.createElement("div", undefined, React.createElement("a", {
                                  className: menuLink + (" " + linkClass),
                                  href: "#",
                                  onClick: onClick
                                }, title)), Belt_Option.mapWithDefault(subtitle, null, (function (t) {
                                return React.createElement("div", undefined, t);
                              })));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.Styles = Styles;
exports.make = make;
/* component Not a pure module */
