// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Api_bs = require("../../common/atd/Api_bs.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WLocationsMap = require("./WLocationsMap");

function make(locations, width, height, children) {
  return ReasonReact.wrapJsForReason(WLocationsMap.default, {
              locations: Belt_Array.map(locations, Api_bs.write_getLocationResponseGetLocationResponseItem),
              width: width,
              height: height
            }, children);
}

exports.make = make;
/* Api_bs Not a pure module */
