// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Sync = require("../common/Sync.bs.js");
var WLog = require("./WLog.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Memoize = require("../common/utils/Memoize.bs.js");
var WNavbar = require("./navigation/WNavbar.bs.js");
var WRoutes = require("./navigation/WRoutes.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var WMessages = require("./WMessages.bs.js");
var LocaleData = require("../common/LocaleData.bs.js");
var CcaMuiTheme = require("./bindings/CcaMuiTheme.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WAuthManager = require("./auth/WAuthManager.bs.js");
var Utils_Navigator = require("../common/utils/Utils_Navigator.bs.js");
var Styles = require("@material-ui/core/styles");

LocaleData.init(/* () */0);

var getMuiTheme = Memoize.memoize((function (theme) {
        return Styles.createMuiTheme({
                    palette: {
                      primary: {
                        main: theme[/* tintColor */0]
                      }
                    }
                  });
      }));

var WSyncManager = Sync.SyncManager([
      WLog.debug,
      WLog.info,
      WLog.warn,
      WLog.error,
      WLog.debug2,
      WLog.info2,
      WLog.warn2,
      WLog.error2
    ]);

var component = ReasonReact.statelessComponent("WApp");

function make(appContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              var messages = WMessages.get(Utils_Navigator.locale);
              var config = appContext[/* config */1];
              var axios = appContext[/* axios */0];
              var realm = appContext[/* keycloakConfig */2].realm;
              var muiTheme = getMuiTheme(config[/* theme */3]);
              return ReasonReact.element(undefined, undefined, CcaMuiTheme.Provider[/* make */0](muiTheme, /* array */[ReasonReact.element(Utils_Navigator.locale, undefined, ReactIntl.IntlProvider[/* make */0](Utils_Navigator.locale, undefined, messages, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, WAuthManager.make(appContext[/* keycloak */3], (function (authContext) {
                                                    return ReasonReact.element(undefined, undefined, Curry._3(WSyncManager[/* make */8], axios, authContext, (function (syncContext) {
                                                                      return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, WNavbar.make(authContext, config, realm, /* array */[])), ReasonReact.element(undefined, undefined, WRoutes.make(syncContext, axios, authContext, /* array */[])));
                                                                    })));
                                                  })))]))]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var $$default = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.appContext, /* array */[]);
      }));

exports.getMuiTheme = getMuiTheme;
exports.WSyncManager = WSyncManager;
exports.component = component;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/*  Not a pure module */
