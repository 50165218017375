// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Memoize = require("../../common/utils/Memoize.bs.js");

var cssTintColor = Memoize.memoize((function (theme) {
        var colorSpec = theme[/* tintColor */0].replace(new RegExp("^#"), "");
        return /* `hex */[
                5194459,
                colorSpec
              ];
      }));

var tintLinkClass = Memoize.memoize((function (theme) {
        var theColor = cssTintColor(theme);
        return Css.style(/* :: */[
                    Css.color(theColor),
                    /* :: */[
                      Css.selector(":visited :hover", /* :: */[
                            Css.color(theColor),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]);
      }));

exports.cssTintColor = cssTintColor;
exports.tintLinkClass = tintLinkClass;
/* cssTintColor Not a pure module */
