// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Printf = require("bs-platform/lib/js/printf.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");

function hours_to_mills(hours) {
  return hours * 60.0 * 60.0 * 1000.0;
}

function fromJsDate(jsDate) {
  return /* record */[
          /* year */jsDate.getFullYear() | 0,
          /* month */1.0 + jsDate.getMonth() | 0,
          /* day */jsDate.getDate() | 0
        ];
}

function toJsDate(date) {
  return new Date(date[/* year */0], date[/* month */1] - 1 | 0, date[/* day */2]);
}

function fromString(str) {
  return fromJsDate(new Date(str + "T00:00:00"));
}

function make() {
  return fromJsDate(new Date());
}

var printf = Printf.sprintf(/* Format */[
      /* Int */Block.__(4, [
          /* Int_d */0,
          /* Lit_padding */Block.__(0, [
              /* Zeros */2,
              4
            ]),
          /* No_precision */0,
          /* Char_literal */Block.__(12, [
              /* "-" */45,
              /* Int */Block.__(4, [
                  /* Int_d */0,
                  /* Lit_padding */Block.__(0, [
                      /* Zeros */2,
                      2
                    ]),
                  /* No_precision */0,
                  /* Char_literal */Block.__(12, [
                      /* "-" */45,
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* Lit_padding */Block.__(0, [
                              /* Zeros */2,
                              2
                            ]),
                          /* No_precision */0,
                          /* End_of_format */0
                        ])
                    ])
                ])
            ])
        ]),
      "%04d-%02d-%02d"
    ]);

function toISOString(date) {
  return Curry._3(printf, date[/* year */0], date[/* month */1], date[/* day */2]);
}

function toInt(date) {
  return (Caml_int32.imul(Caml_int32.imul(date[/* year */0], 100), 100) + Caml_int32.imul(date[/* month */1], 100) | 0) + date[/* day */2] | 0;
}

function compare(a, b) {
  return Caml_primitive.caml_int_compare(toInt(a), toInt(b));
}

function equals(a, b) {
  return compare(a, b) === 0;
}

var LocalDate = /* module */[
  /* fromJsDate */fromJsDate,
  /* toJsDate */toJsDate,
  /* fromString */fromString,
  /* make */make,
  /* printf */printf,
  /* toISOString */toISOString,
  /* toInt */toInt,
  /* compare */compare,
  /* equals */equals
];

function fromJsDate$1(jsDate) {
  return /* record */[/* millis */jsDate.getTime()];
}

function toJsDate$1(date) {
  return new Date(date[/* millis */0]);
}

function make$1() {
  var jsDate = new Date();
  return /* record */[/* millis */jsDate.getTime()];
}

function fromString$1(str) {
  var jsDate = new Date(str);
  return /* record */[/* millis */jsDate.getTime()];
}

function toISOString$1(date) {
  return new Date(date[/* millis */0]).toISOString();
}

function plusHours(date, hours) {
  return /* record */[/* millis */date[/* millis */0] + hours_to_mills(hours)];
}

function compare$1(a, b) {
  return Caml_primitive.caml_float_compare(a[/* millis */0], b[/* millis */0]);
}

function equals$1(a, b) {
  return compare$1(a, b) === 0;
}

function min(a, b) {
  return /* record */[/* millis */Caml_primitive.caml_float_min(a[/* millis */0], b[/* millis */0])];
}

function max(a, b) {
  return /* record */[/* millis */Caml_primitive.caml_float_max(a[/* millis */0], b[/* millis */0])];
}

var Instant = /* module */[
  /* fromJsDate */fromJsDate$1,
  /* toJsDate */toJsDate$1,
  /* make */make$1,
  /* fromString */fromString$1,
  /* toISOString */toISOString$1,
  /* plusHours */plusHours,
  /* compare */compare$1,
  /* equals */equals$1,
  /* min */min,
  /* max */max
];

exports.hours_to_mills = hours_to_mills;
exports.LocalDate = LocalDate;
exports.Instant = Instant;
/* printf Not a pure module */
