// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var dateFormatOptions = {
  localeMatcher: undefined,
  formatMatcher: undefined,
  timeZone: undefined,
  hour12: undefined,
  weekday: undefined,
  era: undefined,
  year: /* NumericNumeralFormat */0,
  month: /* TwoDigitMixedFormat */1,
  day: /* TwoDigitNumeralFormat */1,
  hour: /* TwoDigitNumeralFormat */1,
  minute: /* TwoDigitNumeralFormat */1,
  second: /* TwoDigitNumeralFormat */1,
  timeZoneName: undefined,
  format: undefined
};

var component = ReasonReact.statelessComponent("FormattedInstant");

function make(instant, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, ReactIntl.IntlInjector[/* make */1]((function (intl) {
                                return Curry._2(intl[/* formatDateWithOptions */6], dateFormatOptions, Time.Instant[/* toJsDate */1](instant));
                              })));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.dateFormatOptions = dateFormatOptions;
exports.component = component;
exports.make = make;
/* component Not a pure module */
