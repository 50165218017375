// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Styles = require("../css/Styles.bs.js");
var WRouting = require("../navigation/WRouting.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ExportLink = require("../../common/ExportLink.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var RemoteStore = require("../components/RemoteStore.bs.js");
var Utils_React = require("../../common/utils/Utils_React.bs.js");
var Api_Requests = require("../../common/api/Api_Requests.bs.js");
var WTrainsFilter = require("./WTrainsFilter.bs.js");

function paramsEqual(a, b) {
  return Time.LocalDate[/* equals */8](a[/* operationDay */0], b[/* operationDay */0]);
}

function compareItems(a, b) {
  return $$String.compare(a[/* trainNumber */0], b[/* trainNumber */0]);
}

var TrainsRemoteStore = RemoteStore.MakeRemoteStore(/* module */[
      /* paramsEqual */paramsEqual,
      /* compareItems */compareItems,
      /* componentName */"WTrainsStore"
    ]);

function onChangeDate(date) {
  return WRouting.RouterConfig[/* push */2](/* Trains */Block.__(1, [date]));
}

function makeTrain(train) {
  return React.createElement("tr", {
              key: train[/* trainNumber */0],
              onClick: (function () {
                  return WRouting.RouterConfig[/* push */2](/* Track */Block.__(2, [/* TrainRoute */Block.__(1, [
                                    train[/* trainNumber */0],
                                    train[/* operationDay */1]
                                  ])]));
                })
            }, React.createElement("td", undefined, train[/* trainNumber */0]), React.createElement("td", undefined, Time.LocalDate[/* toISOString */5](train[/* operationDay */1])), React.createElement("td", undefined, train[/* vehicleId */2]), React.createElement("td", undefined, ReasonReact.element(undefined, undefined, Curry._3(WRouting.Router[/* Link */1][/* make */1], /* Track */Block.__(2, [/* TrackerRoute */Block.__(0, [
                                train[/* trackerId */3],
                                train[/* firstGpsTime */4],
                                train[/* lastGpsTime */5]
                              ])]), undefined, /* array */[train[/* trackerId */3]]))), React.createElement("td", undefined, Time.Instant[/* toISOString */4](train[/* firstGpsTime */4])), React.createElement("td", undefined, Time.Instant[/* toISOString */4](train[/* lastGpsTime */5])), React.createElement("td", undefined, String(train[/* numberOfWaypoints */6])), React.createElement("td", undefined, ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("gpx", train[/* trainNumber */0], train[/* operationDay */1], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.gpx", "GPX", undefined, undefined, /* array */[]))])), " ", ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("csv", train[/* trainNumber */0], train[/* operationDay */1], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.csv", "CSV", undefined, undefined, /* array */[]))])), " ", ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("json", train[/* trainNumber */0], train[/* operationDay */1], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.json", "JSON", undefined, undefined, /* array */[]))])), " ", ReasonReact.element(undefined, undefined, ExportLink.Train[/* make */1]("xlsx", train[/* trainNumber */0], train[/* operationDay */1], /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.xlsx", "XLSX", undefined, undefined, /* array */[]))]))));
}

var component = ReasonReact.statelessComponent("WTrainsScreen");

function make(operationDay, axios, authContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              var params = /* record */[/* operationDay */operationDay];
              var fetcher = function (param) {
                return Api_Requests.getTrains(param[/* operationDay */0], axios, authContext[/* refreshToken */1]);
              };
              var loadingElement = React.createElement("div", {
                    className: Styles.DataView[/* message */1]
                  }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.loading", "Loading", undefined, undefined, /* array */[])));
              var noItemsElement = React.createElement("div", {
                    className: Styles.DataView[/* message */1]
                  }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.noTrains", "No trains found", undefined, undefined, /* array */[])));
              var errorElement = function () {
                return React.createElement("div", {
                            className: Styles.DataView[/* message */1]
                          }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.error", "Error on fetching trains", undefined, undefined, /* array */[])));
              };
              var listElement = function (trackers) {
                return React.createElement("div", {
                            className: Styles.DataView[/* table */2]
                          }, React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.train", "Train", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.operationDay", "Operation Day", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.vehicle", "Vehicle", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.tracker", "Tracker", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.start", "Start", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.end", "End", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.numberOfWaypoints", "# Waypoints", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrainsScreen.download", "Download", undefined, undefined, /* array */[]))))), React.createElement("tbody", undefined, Utils_React.mapListToReactElements(trackers, makeTrain))));
              };
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, WTrainsFilter.make(operationDay, onChangeDate, undefined, /* array */[])), ReasonReact.element(undefined, undefined, Curry._7(TrainsRemoteStore[/* make */2], params, fetcher, loadingElement, errorElement, listElement, noItemsElement, /* array */[])));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.TrainsRemoteStore = TrainsRemoteStore;
exports.onChangeDate = onChangeDate;
exports.makeTrain = makeTrain;
exports.component = component;
exports.make = make;
/* TrainsRemoteStore Not a pure module */
