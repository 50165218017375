// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Memoize = require("../../common/utils/Memoize.bs.js");
var WReRoute = require("./WReRoute.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function splitPath(path) {
  var match = path.startsWith("/");
  var trimmed = match ? path.substr(1) : path;
  var match$1 = trimmed.endsWith("/");
  var trimmed$1 = match$1 ? trimmed.substring(0, trimmed.length - 2 | 0) : trimmed;
  return Belt_List.fromArray(trimmed$1.split("/"));
}

var getHashRoute = Memoize.memoize((function (hash) {
        var match = hash.split("?");
        if (match.length !== 2) {
          return /* record */[
                  /* path */splitPath(hash),
                  /* queryString */undefined
                ];
        } else {
          var pathString = match[0];
          var queryString = match[1];
          return /* record */[
                  /* path */splitPath(pathString),
                  /* queryString */queryString
                ];
        }
      }));

function routeFromUrl(url) {
  var hashRoute = getHashRoute(url[/* hash */1]);
  var match = hashRoute[/* path */0];
  if (match) {
    switch (match[0]) {
      case "admin" : 
          if (match[1]) {
            return /* NotFound */5;
          } else {
            return /* Administration */3;
          }
      case "locations" : 
          if (match[1]) {
            return /* NotFound */5;
          } else {
            return /* Locations */1;
          }
      case "main" : 
          if (match[1]) {
            return /* NotFound */5;
          } else {
            return /* Main */0;
          }
      case "tests" : 
          if (match[1]) {
            return /* NotFound */5;
          } else {
            return /* Tests */4;
          }
      case "trackers" : 
          var match$1 = match[1];
          if (match$1) {
            var fromTime = match$1[0];
            var exit = 0;
            if (fromTime === "mappings" && !match$1[1]) {
              return /* TrackerMappings */2;
            } else {
              exit = 1;
            }
            if (exit === 1) {
              var match$2 = match$1[1];
              if (match$2) {
                var toTime = match$2[0];
                if (match$2[1]) {
                  if (toTime === "track") {
                    var match$3 = match$2[1];
                    var match$4 = match$3[1];
                    var fromTime$1 = match$3[0];
                    if (match$4) {
                      if (match$4[1]) {
                        return /* NotFound */5;
                      } else {
                        return /* Track */Block.__(2, [/* TrackerRoute */Block.__(0, [
                                      fromTime,
                                      Time.Instant[/* fromString */3](fromTime$1),
                                      Time.Instant[/* fromString */3](match$4[0])
                                    ])]);
                      }
                    } else {
                      return /* Track */Block.__(2, [/* TrackerRoute */Block.__(0, [
                                    fromTime,
                                    Time.Instant[/* fromString */3](fromTime$1),
                                    undefined
                                  ])]);
                    }
                  } else {
                    return /* NotFound */5;
                  }
                } else {
                  return /* Trackers */Block.__(0, [
                            Time.Instant[/* fromString */3](fromTime),
                            Time.Instant[/* fromString */3](toTime)
                          ]);
                }
              } else {
                return /* Trackers */Block.__(0, [
                          Time.Instant[/* fromString */3](fromTime),
                          undefined
                        ]);
              }
            }
            
          } else {
            return /* Trackers */Block.__(0, [
                      Time.Instant[/* make */2](/* () */0),
                      undefined
                    ]);
          }
          break;
      case "trains" : 
          var match$5 = match[1];
          if (match$5) {
            var match$6 = match$5[1];
            var operationDay = match$5[0];
            if (match$6) {
              var match$7 = match$6[1];
              if (match$7 && match$7[0] === "track" && !match$7[1]) {
                return /* Track */Block.__(2, [/* TrainRoute */Block.__(1, [
                              match$6[0],
                              Time.LocalDate[/* fromString */2](operationDay)
                            ])]);
              } else {
                return /* NotFound */5;
              }
            } else {
              return /* Trains */Block.__(1, [Time.LocalDate[/* fromString */2](operationDay)]);
            }
          } else {
            return /* Trains */Block.__(1, [Time.LocalDate[/* make */3](/* () */0)]);
          }
      default:
        return /* NotFound */5;
    }
  } else {
    return /* NotFound */5;
  }
}

function routeToUrl(route) {
  if (typeof route === "number") {
    switch (route) {
      case 0 : 
          return "/#/main";
      case 1 : 
          return "/#/locations";
      case 2 : 
          return "/#/trackers/mappings";
      case 3 : 
          return "/#/admin";
      case 4 : 
          return "/#/tests";
      case 5 : 
          return "/#/";
      
    }
  } else {
    switch (route.tag | 0) {
      case 0 : 
          var match = route[1];
          var fromTime = route[0];
          if (match !== undefined) {
            return "/#/trackers/" + (Time.Instant[/* toISOString */4](fromTime) + ("/" + Time.Instant[/* toISOString */4](match)));
          } else {
            return "/#/trackers/" + Time.Instant[/* toISOString */4](fromTime);
          }
      case 1 : 
          return "/#/trains/" + Time.LocalDate[/* toISOString */5](route[0]);
      case 2 : 
          var match$1 = route[0];
          if (match$1.tag) {
            return "/#/trains/" + (Time.LocalDate[/* toISOString */5](match$1[1]) + ("/" + (match$1[0] + "/track")));
          } else {
            var match$2 = match$1[2];
            var fromTime$1 = match$1[1];
            var trackerId = match$1[0];
            if (match$2 !== undefined) {
              return "/#/trackers/" + (trackerId + ("/track/" + (Time.Instant[/* toISOString */4](fromTime$1) + ("/" + Time.Instant[/* toISOString */4](match$2)))));
            } else {
              return "/#/trackers/" + (trackerId + ("/track/" + Time.Instant[/* toISOString */4](fromTime$1)));
            }
          }
      
    }
  }
}

function push(route) {
  return ReasonReact.Router[/* push */0](routeToUrl(route));
}

function redirect(route) {
  setTimeout((function () {
          return ReasonReact.Router[/* push */0](routeToUrl(route));
        }), 0);
  return null;
}

var RouterConfig = /* module */[
  /* routeFromUrl */routeFromUrl,
  /* routeToUrl */routeToUrl,
  /* push */push,
  /* redirect */redirect
];

var Router = WReRoute.CreateRouter([
      routeFromUrl,
      routeToUrl
    ]);

exports.splitPath = splitPath;
exports.getHashRoute = getHashRoute;
exports.RouterConfig = RouterConfig;
exports.Router = Router;
/* getHashRoute Not a pure module */
