// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");

var jsMapperConstantArray = /* array */[
  /* tuple */[
    -1069786045,
    "add_camera"
  ],
  /* tuple */[
    -1049964332,
    "add_photo"
  ],
  /* tuple */[
    -989121855,
    "help"
  ],
  /* tuple */[
    -915500580,
    "incident_accident"
  ],
  /* tuple */[
    -844262837,
    "user"
  ],
  /* tuple */[
    -539587215,
    "drawer"
  ],
  /* tuple */[
    -423229174,
    "incident_vandalism"
  ],
  /* tuple */[
    -415737326,
    "incident_theft"
  ],
  /* tuple */[
    188579043,
    "settings"
  ],
  /* tuple */[
    237521123,
    "incident_other"
  ],
  /* tuple */[
    273174671,
    "share_android"
  ],
  /* tuple */[
    653754616,
    "add_audio"
  ],
  /* tuple */[
    764221224,
    "notifications"
  ],
  /* tuple */[
    790533676,
    "incident_property_damage"
  ],
  /* tuple */[
    913513629,
    "add_video"
  ],
  /* tuple */[
    1003852589,
    "share_ios"
  ]
];

function iconNameToJs(param) {
  return Js_mapperRt.binarySearch(16, param, jsMapperConstantArray);
}

function iconNameFromJs(param) {
  return Js_mapperRt.revSearch(16, jsMapperConstantArray, param);
}

exports.iconNameToJs = iconNameToJs;
exports.iconNameFromJs = iconNameFromJs;
/* No side effect */
