// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js");
var MaterialUi_Dialog = require("@jsiebern/bs-material-ui/src/MaterialUi_Dialog.bs.js");
var MaterialUi_DialogTitle = require("@jsiebern/bs-material-ui/src/MaterialUi_DialogTitle.bs.js");
var MaterialUi_DialogActions = require("@jsiebern/bs-material-ui/src/MaterialUi_DialogActions.bs.js");
var MaterialUi_DialogContent = require("@jsiebern/bs-material-ui/src/MaterialUi_DialogContent.bs.js");

var subHeading = Css.style(/* :: */[
      Css.marginTop(Css.px(14)),
      /* :: */[
        Css.marginBottom(Css.px(4)),
        /* [] */0
      ]
    ]);

var subSection = Css.style(/* :: */[
      Css.paddingBottom(Css.px(16)),
      /* [] */0
    ]);

var Styles = /* module */[
  /* subHeading */subHeading,
  /* subSection */subSection
];

var component = ReasonReact.statelessComponent("WMenuModal");

function make(open_, title, dispatch, children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, MaterialUi_Dialog.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, open_, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[
                              ReasonReact.element(undefined, undefined, MaterialUi_DialogTitle.make(undefined, undefined, undefined, undefined, /* array */[title])),
                              ReasonReact.element(undefined, undefined, MaterialUi_DialogContent.make(undefined, undefined, undefined, children)),
                              ReasonReact.element(undefined, undefined, MaterialUi_DialogActions.make(undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                                                    return Curry._1(dispatch, /* CloseModal */0);
                                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WMenuModal.Close", "Close", undefined, undefined, /* array */[]))]))]))
                            ]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* subHeading Not a pure module */
