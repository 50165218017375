// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Api_bs = require("./Api_bs.bs.js");
var Atd_Adapters = require("./Atd_Adapters.bs.js");
var Atdgen_codec_runtime = require("@ahrefs/bs-atdgen-codec-runtime/src/atdgen_codec_runtime.bs.js");

var write_versionInfo = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "version", t[/* version */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "build", t[/* build */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_versionInfo = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* version */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("version", partial_arg, param);
                      }), json),
                /* build */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("build", partial_arg$2, param);
                      }), json)
              ];
      }));

Atdgen_codec_runtime.Decode[/* option_as_constr */25](Atdgen_codec_runtime.Decode[/* string */7]);

var partial_arg = Atdgen_codec_runtime.Encode[/* string */3];

var partial_arg$1 = Atdgen_codec_runtime.Encode[/* list */8];

function write__3(param) {
  return partial_arg$1(partial_arg, param);
}

var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];

var partial_arg$3 = Atdgen_codec_runtime.Decode[/* list */11];

function read__3(param) {
  return partial_arg$3(partial_arg$2, param);
}

var write_userInfo = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "username", t[/* username */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "email", t[/* email */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "firstName", t[/* firstName */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "lastName", t[/* lastName */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field */11](undefined, write__3, "roles", t[/* roles */4]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_userInfo = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* username */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("username", partial_arg, param);
                      }), json),
                /* email */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("email", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* firstName */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("firstName", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* lastName */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("lastName", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* roles */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$2("roles", read__3, param);
                      }), json)
              ];
      }));

var write_theme = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "tintColor", t[/* tintColor */0]),
                    /* [] */0
                  ]);
      }));

var read_theme = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[/* tintColor */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("tintColor", partial_arg, param);
                      }), json)];
      }));

var write_supportInfo = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "phoneNumber", t[/* phoneNumber */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "email", t[/* email */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_supportInfo = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* phoneNumber */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("phoneNumber", partial_arg, param);
                      }), json),
                /* email */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("email", partial_arg$2, param);
                      }), json)
              ];
      }));

var write__1 = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](List.map((function (param) {
                          return Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], param[0], param[1]);
                        }), t));
      }));

var partial_arg$4 = Atdgen_codec_runtime.Decode[/* string */7];

var partial_arg$5 = Atdgen_codec_runtime.Decode[/* obj_list */13];

function read__1(param) {
  return partial_arg$5(partial_arg$4, param);
}

var partial_arg$6 = Atd_Adapters.StringMap[/* unwrap */1];

var partial_arg$7 = Atdgen_codec_runtime.Encode[/* contramap */20];

function write__2(param) {
  return partial_arg$7(partial_arg$6, write__1, param);
}

var partial_arg$8 = Atd_Adapters.StringMap[/* wrap */0];

var partial_arg$9 = Atdgen_codec_runtime.Decode[/* map */18];

function read__2(param) {
  return partial_arg$9(partial_arg$8, read__1, param);
}

var write_navbarItem = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, write__2, "title", t[/* title */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "rootUrl", t[/* rootUrl */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "path", t[/* path */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, write__3, "roles", t[/* roles */3]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]);
      }));

var read_navbarItem = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* title */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg("title", read__2, param);
                      }), json),
                /* rootUrl */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$2("rootUrl", partial_arg$1, param);
                      }), json),
                /* path */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$4("path", partial_arg$3, param);
                      }), json),
                /* roles */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$5("roles", read__3, param);
                      }), json)
              ];
      }));

var write_license = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "name", t[/* name */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "version", t[/* version */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "licenseType", t[/* licenseType */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "url", t[/* url */3]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]);
      }));

var read_license = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$4 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$5 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$6 = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$7 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* name */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("name", partial_arg, param);
                      }), json),
                /* version */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("version", partial_arg$2, param);
                      }), json),
                /* licenseType */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$5("licenseType", partial_arg$4, param);
                      }), json),
                /* url */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$7("url", partial_arg$6, param);
                      }), json)
              ];
      }));

var partial_arg$10 = Atdgen_codec_runtime.Encode[/* list */8];

function write__6(param) {
  return partial_arg$10(write_license, param);
}

var partial_arg$11 = Atdgen_codec_runtime.Decode[/* list */11];

function read__6(param) {
  return partial_arg$11(read_license, param);
}

var write_keycloakRealmAccess = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, write__3, "roles", t[/* roles */0]),
                    /* [] */0
                  ]);
      }));

var read_keycloakRealmAccess = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[/* roles */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg("roles", read__3, param);
                      }), json)];
      }));

Atdgen_codec_runtime.Decode[/* option_as_constr */25](read_keycloakRealmAccess);

var write_keycloakUserInfo = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "preferred_username", t[/* preferredUsername */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "email", t[/* email */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "given_name", t[/* givenName */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "family_name", t[/* familyName */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field_o */12](undefined, write_keycloakRealmAccess, "realm_access", t[/* realmAccess */4]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_keycloakUserInfo = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* preferredUsername */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("preferred_username", partial_arg, param);
                      }), json),
                /* email */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("email", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* givenName */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("given_name", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* familyName */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("family_name", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* realmAccess */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("realm_access", read_keycloakRealmAccess), json)
              ];
      }));

var write_json = Atd_Adapters.JsJson[/* write_json */0];

var read_json = Atd_Adapters.JsJson[/* read_json */1];

var partial_arg$12 = Atdgen_codec_runtime.Encode[/* list */8];

function write__7(param) {
  return partial_arg$12(write_navbarItem, param);
}

var partial_arg$13 = Atdgen_codec_runtime.Decode[/* list */11];

function read__7(param) {
  return partial_arg$13(read_navbarItem, param);
}

Atdgen_codec_runtime.Decode[/* option_as_constr */25](read__7);

var write_config = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "siteName", t[/* siteName */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field_o */12](undefined, Atdgen_codec_runtime.Encode[/* string */3], "forcedLanguageCode", t[/* forcedLanguageCode */1]),
                      /* :: */[
                        Atdgen_codec_runtime.Encode[/* field */11](undefined, write_supportInfo, "supportInfo", t[/* supportInfo */2]),
                        /* :: */[
                          Atdgen_codec_runtime.Encode[/* field */11](undefined, write_theme, "theme", t[/* theme */3]),
                          /* :: */[
                            Atdgen_codec_runtime.Encode[/* field_o */12](undefined, write__7, "navbarItems", t[/* navbarItems */4]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
      }));

var read_config = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$2 = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$3 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* siteName */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("siteName", partial_arg, param);
                      }), json),
                /* forcedLanguageCode */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("forcedLanguageCode", Atdgen_codec_runtime.Decode[/* string */7]), json),
                /* supportInfo */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$2("supportInfo", read_supportInfo, param);
                      }), json),
                /* theme */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$3("theme", read_theme, param);
                      }), json),
                /* navbarItems */Atdgen_codec_runtime.Decode[/* decode */1](Atdgen_codec_runtime.Decode[/* fieldOptional */17]("navbarItems", read__7), json)
              ];
      }));

var write_configState = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, write_config, "config", t[/* config */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, Api_bs.write_getConfigResponseKeycloakConfiguration, "keycloakConfiguration", t[/* keycloakConfiguration */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_configState = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* config */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg("config", read_config, param);
                      }), json),
                /* keycloakConfiguration */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("keycloakConfiguration", Api_bs.read_getConfigResponseKeycloakConfiguration, param);
                      }), json)
              ];
      }));

var partial_arg$14 = Atdgen_codec_runtime.Encode[/* string */3];

var partial_arg$15 = Atd_Adapters.JsDate[/* unwrap */1];

var partial_arg$16 = Atdgen_codec_runtime.Encode[/* contramap */20];

function write__9(param) {
  return partial_arg$16(partial_arg$15, partial_arg$14, param);
}

var partial_arg$17 = Atdgen_codec_runtime.Decode[/* string */7];

var partial_arg$18 = Atd_Adapters.JsDate[/* wrap */0];

var partial_arg$19 = Atdgen_codec_runtime.Decode[/* map */18];

function read__9(param) {
  return partial_arg$19(partial_arg$18, partial_arg$17, param);
}

var write_authState = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, write_json, "tokenData", t[/* tokenData */0]),
                    /* :: */[
                      Atdgen_codec_runtime.Encode[/* field */11](undefined, write__9, "creationTimestamp", t[/* creationTimestamp */1]),
                      /* [] */0
                    ]
                  ]);
      }));

var read_authState = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* field */15];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[
                /* tokenData */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg("tokenData", read_json, param);
                      }), json),
                /* creationTimestamp */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("creationTimestamp", read__9, param);
                      }), json)
              ];
      }));

var write_appConfig = Atdgen_codec_runtime.Encode[/* make */0]((function (t) {
        return Atdgen_codec_runtime.Encode[/* obj */13](/* :: */[
                    Atdgen_codec_runtime.Encode[/* field */11](undefined, Atdgen_codec_runtime.Encode[/* string */3], "serverUrl", t[/* serverUrl */0]),
                    /* [] */0
                  ]);
      }));

var read_appConfig = Atdgen_codec_runtime.Decode[/* make */0]((function (json) {
        var partial_arg = Atdgen_codec_runtime.Decode[/* string */7];
        var partial_arg$1 = Atdgen_codec_runtime.Decode[/* field */15];
        return /* record */[/* serverUrl */Atdgen_codec_runtime.Decode[/* decode */1]((function (param) {
                        return partial_arg$1("serverUrl", partial_arg, param);
                      }), json)];
      }));

var read_stringMap = read__2;

var write_stringMap = write__2;

var read_licenseList = read__6;

var write_licenseList = write__6;

var read_getConfigResponseKeycloakConfiguration = Api_bs.read_getConfigResponseKeycloakConfiguration;

var write_getConfigResponseKeycloakConfiguration = Api_bs.write_getConfigResponseKeycloakConfiguration;

exports.read_versionInfo = read_versionInfo;
exports.write_versionInfo = write_versionInfo;
exports.read_userInfo = read_userInfo;
exports.write_userInfo = write_userInfo;
exports.read_theme = read_theme;
exports.write_theme = write_theme;
exports.read_supportInfo = read_supportInfo;
exports.write_supportInfo = write_supportInfo;
exports.read_stringMap = read_stringMap;
exports.write_stringMap = write_stringMap;
exports.read_navbarItem = read_navbarItem;
exports.write_navbarItem = write_navbarItem;
exports.read_license = read_license;
exports.write_license = write_license;
exports.read_licenseList = read_licenseList;
exports.write_licenseList = write_licenseList;
exports.read_keycloakRealmAccess = read_keycloakRealmAccess;
exports.write_keycloakRealmAccess = write_keycloakRealmAccess;
exports.read_keycloakUserInfo = read_keycloakUserInfo;
exports.write_keycloakUserInfo = write_keycloakUserInfo;
exports.read_json = read_json;
exports.write_json = write_json;
exports.read_getConfigResponseKeycloakConfiguration = read_getConfigResponseKeycloakConfiguration;
exports.write_getConfigResponseKeycloakConfiguration = write_getConfigResponseKeycloakConfiguration;
exports.read_config = read_config;
exports.write_config = write_config;
exports.read_configState = read_configState;
exports.write_configState = write_configState;
exports.read_authState = read_authState;
exports.write_authState = write_authState;
exports.read_appConfig = read_appConfig;
exports.write_appConfig = write_appConfig;
/* write_versionInfo Not a pure module */
