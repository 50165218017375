// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function memoize(f) {
  var memoized = /* record */[/* contents */undefined];
  var compute = function (param) {
    var value = Curry._1(f, param);
    memoized[0] = /* tuple */[
      param,
      value
    ];
    return value;
  };
  return (function (param) {
      var match = memoized[0];
      if (match !== undefined) {
        var match$1 = match;
        var match$2 = param === match$1[0];
        if (match$2) {
          return match$1[1];
        } else {
          return compute(param);
        }
      } else {
        return compute(param);
      }
    });
}

function memoize2(f) {
  var memoized = /* record */[/* contents */undefined];
  var compute = function (p1, p2) {
    var value = Curry._2(f, p1, p2);
    memoized[0] = /* tuple */[
      p1,
      p2,
      value
    ];
    return value;
  };
  return (function (p1, p2) {
      var match = memoized[0];
      if (match !== undefined) {
        var match$1 = match;
        var match$2 = p1 === match$1[0] && p2 === match$1[1];
        if (match$2) {
          return match$1[2];
        } else {
          return compute(p1, p2);
        }
      } else {
        return compute(p1, p2);
      }
    });
}

function memoize3(f) {
  var memoized = /* record */[/* contents */undefined];
  var compute = function (p1, p2, p3) {
    var value = Curry._3(f, p1, p2, p3);
    memoized[0] = /* tuple */[
      p1,
      p2,
      p3,
      value
    ];
    return value;
  };
  return (function (p1, p2, p3) {
      var match = memoized[0];
      if (match !== undefined) {
        var match$1 = match;
        var match$2 = p1 === match$1[0] && p2 === match$1[1] && p3 === match$1[2];
        if (match$2) {
          return match$1[3];
        } else {
          return compute(p1, p2, p3);
        }
      } else {
        return compute(p1, p2, p3);
      }
    });
}

exports.memoize = memoize;
exports.memoize2 = memoize2;
exports.memoize3 = memoize3;
/* No side effect */
