// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Time = require("../../common/types/Time.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Styles = require("../css/Styles.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Utils_React = require("../../common/utils/Utils_React.bs.js");
var Api_Requests = require("../../common/api/Api_Requests.bs.js");
var Belt_SetString = require("bs-platform/lib/js/belt_SetString.js");
var MaterialUi_Input = require("@jsiebern/bs-material-ui/src/MaterialUi_Input.bs.js");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js");

var notMapped = Css.style(/* :: */[
      Css.fontStyle(/* italic */107228912),
      /* [] */0
    ]);

var mappingError = Css.style(/* :: */[
      Css.color(/* `hex */[
            5194459,
            "c00b25"
          ]),
      /* [] */0
    ]);

var LocalStyles = /* module */[
  /* notMapped */notMapped,
  /* mappingError */mappingError
];

function canSubmitForSave(mapping) {
  var match = mapping[/* edit */2];
  if (match !== undefined) {
    var edit = match;
    if (mapping[/* state */3] !== /* Submitting */1 && edit[/* vehicleId */0] !== "") {
      return edit[/* vehicleId */0] !== Belt_Option.getWithDefault(mapping[/* vehicleId */1], "");
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function makeTrackerMapping(send, mapping) {
  var match = mapping[/* edit */2];
  var tmp;
  if (match !== undefined) {
    tmp = ReasonReact.element(undefined, undefined, MaterialUi_Input.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (value) {
                return Curry._1(send, /* MappingChange */Block.__(3, [
                              mapping[/* trackerId */0],
                              value.target.value
                            ]));
              }), undefined, undefined, undefined, undefined, (function (e) {
                if (!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey && e.key === "Enter" && canSubmitForSave(mapping)) {
                  e.preventDefault();
                  return Curry._1(send, /* SaveMapping */Block.__(4, [mapping[/* trackerId */0]]));
                } else {
                  return 0;
                }
              }), undefined, undefined, undefined, undefined, undefined, undefined, /* `String */[
              -976970511,
              match[/* vehicleId */0]
            ], undefined, undefined, /* array */[]));
  } else {
    var match$1 = mapping[/* vehicleId */1];
    tmp = match$1 !== undefined ? React.createElement(React.Fragment, undefined, match$1) : React.createElement("span", {
            className: notMapped
          }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.notMapped", "Not mapped", undefined, undefined, /* array */[])));
  }
  var match$2 = mapping[/* edit */2];
  var match$3 = mapping[/* edit */2];
  var tmp$1;
  if (match$3 !== undefined) {
    tmp$1 = ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, mapping[/* state */3] === /* Submitting */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                return Curry._1(send, /* CancelEdit */Block.__(6, [mapping[/* trackerId */0]]));
              }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.cancel", "Cancel", undefined, undefined, /* array */[]))]));
  } else {
    var match$4 = mapping[/* vehicleId */1];
    tmp$1 = match$4 !== undefined ? ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, mapping[/* state */3] === /* Submitting */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                  return Curry._1(send, /* DeleteMapping */Block.__(7, [mapping[/* trackerId */0]]));
                }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.delete", "Delete", undefined, undefined, /* array */[]))])) : null;
  }
  var match$5 = mapping[/* state */3];
  return React.createElement("tr", {
              key: mapping[/* trackerId */0]
            }, React.createElement("td", undefined, mapping[/* trackerId */0]), React.createElement("td", undefined, tmp), React.createElement("td", undefined, match$2 !== undefined ? ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, !canSubmitForSave(mapping), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                              return Curry._1(send, /* SaveMapping */Block.__(4, [mapping[/* trackerId */0]]));
                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.save", "Save", undefined, undefined, /* array */[]))])) : ReasonReact.element(undefined, undefined, MaterialUi_Button.make(undefined, /* Primary */-791844958, undefined, mapping[/* state */3] === /* Submitting */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Raised */-387916264, undefined, undefined, undefined, undefined, undefined, undefined, (function () {
                              return Curry._1(send, /* Edit */Block.__(2, [mapping[/* trackerId */0]]));
                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* array */[ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.edit", "Edit", undefined, undefined, /* array */[]))])), tmp$1, typeof match$5 === "number" ? null : React.createElement("div", {
                        className: mappingError
                      }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.mappingError", "Error on updating mapping", undefined, undefined, /* array */[])))));
}

function fetch(self, axios, authContext) {
  Curry._1(self[/* send */3], /* Loading */0);
  var trackerMapper = function (item) {
    return /* record */[
            /* trackerId */item[/* trackerId */0],
            /* vehicleId */undefined,
            /* edit */undefined,
            /* state : UserInput */0,
            /* activeTracker */true
          ];
  };
  var trackerMappingMapper = function (item) {
    return /* record */[
            /* trackerId */item[/* trackerId */0],
            /* vehicleId */item[/* vehicleId */1],
            /* edit */undefined,
            /* state : UserInput */0,
            /* activeTracker */false
          ];
  };
  var mergeMappingsAndPossibilities = function (mappings, possibles) {
    var mappedTrackerIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(mappings, (function (m) {
                    return m[/* trackerId */0];
                  }))));
    var activeTrackerIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(possibles, (function (m) {
                    return m[/* trackerId */0];
                  }))));
    return Belt_List.map(Belt_List.concat(mappings, Belt_List.keep(possibles, (function (m) {
                          return !Belt_SetString.has(mappedTrackerIds, m[/* trackerId */0]);
                        }))), (function (mapping) {
                  return /* record */[
                          /* trackerId */mapping[/* trackerId */0],
                          /* vehicleId */mapping[/* vehicleId */1],
                          /* edit */mapping[/* edit */2],
                          /* state */mapping[/* state */3],
                          /* activeTracker */Belt_SetString.has(activeTrackerIds, mapping[/* trackerId */0])
                        ];
                }));
  };
  var fromTime = Time.Instant[/* plusHours */5](Time.Instant[/* make */2](/* () */0), -24);
  return Repromise.wait((function (result) {
                if (result.tag) {
                  return Curry._1(self[/* send */3], /* FetchError */Block.__(1, [result[0]]));
                } else {
                  return Curry._1(self[/* send */3], /* Fetched */Block.__(0, [result[0]]));
                }
              }), Repromise.map((function (result) {
                    if (result.tag) {
                      return result;
                    } else {
                      return /* Ok */Block.__(0, [Belt_List.sort(result[0], (function (a, b) {
                                        return $$String.compare(a[/* trackerId */0], b[/* trackerId */0]);
                                      }))]);
                    }
                  }), Repromise.map((function (result) {
                        if (result) {
                          var match = result[0];
                          if (match.tag) {
                            var match$1 = result[1];
                            if (match$1 && !match$1[1]) {
                              return /* Error */Block.__(1, [match[0]]);
                            } else {
                              return /* Error */Block.__(1, [/* record */[
                                          /* errorType : FetchError */0,
                                          /* message */"Unpromised"
                                        ]]);
                            }
                          } else {
                            var match$2 = result[1];
                            if (match$2) {
                              var match$3 = match$2[0];
                              if (match$3.tag) {
                                var match$4 = result[1];
                                if (match$4[1]) {
                                  return /* Error */Block.__(1, [/* record */[
                                              /* errorType : FetchError */0,
                                              /* message */"Unpromised"
                                            ]]);
                                } else {
                                  return /* Error */Block.__(1, [match$4[0][0]]);
                                }
                              } else if (match$2[1]) {
                                return /* Error */Block.__(1, [/* record */[
                                            /* errorType : FetchError */0,
                                            /* message */"Unpromised"
                                          ]]);
                              } else {
                                return /* Ok */Block.__(0, [mergeMappingsAndPossibilities(match[0], match$3[0])]);
                              }
                            } else {
                              return /* Error */Block.__(1, [/* record */[
                                          /* errorType : FetchError */0,
                                          /* message */"Unpromised"
                                        ]]);
                            }
                          }
                        } else {
                          return /* Error */Block.__(1, [/* record */[
                                      /* errorType : FetchError */0,
                                      /* message */"Unpromised"
                                    ]]);
                        }
                      }), Repromise.all(/* :: */[
                          Repromise.map((function (result) {
                                  return Belt_Result.map(result, (function (list) {
                                                return Belt_List.map(list, trackerMappingMapper);
                                              }));
                                }), Api_Requests.getTrackerMappings(axios, authContext[/* refreshToken */1])),
                          /* :: */[
                            Repromise.map((function (result) {
                                    return Belt_Result.map(result, (function (list) {
                                                  return Belt_List.map(list, trackerMapper);
                                                }));
                                  }), Api_Requests.getTrackers(fromTime, undefined, axios, authContext[/* refreshToken */1])),
                            /* [] */0
                          ]
                        ]))));
}

var loadingElement = React.createElement("div", {
      className: Styles.DataView[/* message */1]
    }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.loading", "Loading", undefined, undefined, /* array */[])));

var noItemsElement = React.createElement("div", {
      className: Styles.DataView[/* message */1]
    }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.noTrackers", "No trackers found", undefined, undefined, /* array */[])));

function errorElement() {
  return React.createElement("div", {
              className: Styles.DataView[/* message */1]
            }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.error", "Error on fetching tracker mappings", undefined, undefined, /* array */[])));
}

function listElement(send, mappings) {
  return React.createElement("div", {
              className: Styles.DataView[/* table */2]
            }, React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.tracker", "Tracker", undefined, undefined, /* array */[]))), React.createElement("th", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackerMappingsScreen.vehicle", "Vehicle", undefined, undefined, /* array */[]))), React.createElement("th", undefined))), React.createElement("tbody", undefined, Utils_React.mapListToReactElements(mappings, (function (param) {
                            return makeTrackerMapping(send, param);
                          })))));
}

var component = ReasonReact.reducerComponent("WTrackerMappingsScreen");

function updateItemInState(state, trackerId, updateFn) {
  return /* record */[
          /* items */Belt_List.map(state[/* items */0], (function (item) {
                  if (item[/* trackerId */0] === trackerId) {
                    return Curry._1(updateFn, item);
                  } else {
                    return item;
                  }
                })),
          /* error */state[/* error */1],
          /* loading */state[/* loading */2]
        ];
}

function make(axios, authContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return fetch(self, axios, authContext);
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var tmp;
              if (self[/* state */1][/* loading */2]) {
                tmp = loadingElement;
              } else {
                var match = self[/* state */1][/* error */1];
                tmp = match !== undefined ? errorElement(match) : (
                    Belt_List.length(self[/* state */1][/* items */0]) > 0 ? listElement(self[/* send */3], self[/* state */1][/* items */0]) : noItemsElement
                  );
              }
              return React.createElement(React.Fragment, undefined, tmp);
            }),
          /* initialState */(function () {
              return /* record */[
                      /* items : [] */0,
                      /* error */undefined,
                      /* loading */false
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [/* record */[
                            /* items */state[/* items */0],
                            /* error */undefined,
                            /* loading */true
                          ]]);
              } else {
                switch (action.tag | 0) {
                  case 0 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* items */action[0],
                                  /* error */undefined,
                                  /* loading */false
                                ]]);
                  case 1 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* items */state[/* items */0],
                                  /* error */action[0],
                                  /* loading */false
                                ]]);
                  case 2 : 
                      return /* Update */Block.__(0, [updateItemInState(state, action[0], (function (item) {
                                        if (Belt_Option.isNone(item[/* edit */2])) {
                                          return /* record */[
                                                  /* trackerId */item[/* trackerId */0],
                                                  /* vehicleId */item[/* vehicleId */1],
                                                  /* edit *//* record */[/* vehicleId */Belt_Option.getWithDefault(item[/* vehicleId */1], "")],
                                                  /* state */item[/* state */3],
                                                  /* activeTracker */item[/* activeTracker */4]
                                                ];
                                        } else {
                                          return item;
                                        }
                                      }))]);
                  case 3 : 
                      var value = action[1];
                      return /* Update */Block.__(0, [updateItemInState(state, action[0], (function (item) {
                                        var match = item[/* edit */2];
                                        if (match !== undefined) {
                                          return /* record */[
                                                  /* trackerId */item[/* trackerId */0],
                                                  /* vehicleId */item[/* vehicleId */1],
                                                  /* edit *//* record */[/* vehicleId */value],
                                                  /* state */item[/* state */3],
                                                  /* activeTracker */item[/* activeTracker */4]
                                                ];
                                        } else {
                                          return item;
                                        }
                                      }))]);
                  case 4 : 
                      var trackerId = action[0];
                      var match = Belt_List.getBy(state[/* items */0], (function (item) {
                              return item[/* trackerId */0] === trackerId;
                            }));
                      if (match !== undefined) {
                        var match$1 = match[/* edit */2];
                        if (match$1 !== undefined) {
                          var edit = match$1;
                          return /* UpdateWithSideEffects */Block.__(2, [
                                    updateItemInState(state, trackerId, (function (item) {
                                            return /* record */[
                                                    /* trackerId */item[/* trackerId */0],
                                                    /* vehicleId */item[/* vehicleId */1],
                                                    /* edit */item[/* edit */2],
                                                    /* state : Submitting */1,
                                                    /* activeTracker */item[/* activeTracker */4]
                                                  ];
                                          })),
                                    (function (self) {
                                        var mapping = Belt_List.getBy(state[/* items */0], (function (item) {
                                                if (Belt_Option.isSome(item[/* edit */2])) {
                                                  return item[/* trackerId */0] === trackerId;
                                                } else {
                                                  return false;
                                                }
                                              }));
                                        if (mapping !== undefined) {
                                          return Repromise.Rejectable[/* wait */6]((function (result) {
                                                        if (result.tag) {
                                                          return Curry._1(self[/* send */3], /* MappingError */Block.__(9, [
                                                                        trackerId,
                                                                        result[0]
                                                                      ]));
                                                        } else {
                                                          return Curry._1(self[/* send */3], /* MappingChanged */Block.__(5, [
                                                                        trackerId,
                                                                        edit[/* vehicleId */0]
                                                                      ]));
                                                        }
                                                      }), Api_Requests.postTrackerMapping(/* record */[
                                                          /* trackerId */mapping[/* trackerId */0],
                                                          /* vehicleId */edit[/* vehicleId */0]
                                                        ], axios, authContext[/* refreshToken */1]));
                                        } else {
                                          return /* () */0;
                                        }
                                      })
                                  ]);
                        } else {
                          return /* NoUpdate */0;
                        }
                      } else {
                        return /* NoUpdate */0;
                      }
                  case 5 : 
                      var vehicleId = action[1];
                      return /* Update */Block.__(0, [updateItemInState(state, action[0], (function (item) {
                                        return /* record */[
                                                /* trackerId */item[/* trackerId */0],
                                                /* vehicleId */vehicleId,
                                                /* edit */undefined,
                                                /* state : UserInput */0,
                                                /* activeTracker */item[/* activeTracker */4]
                                              ];
                                      }))]);
                  case 6 : 
                      return /* Update */Block.__(0, [updateItemInState(state, action[0], (function (item) {
                                        if (Belt_Option.isSome(item[/* edit */2])) {
                                          return /* record */[
                                                  /* trackerId */item[/* trackerId */0],
                                                  /* vehicleId */item[/* vehicleId */1],
                                                  /* edit */undefined,
                                                  /* state : UserInput */0,
                                                  /* activeTracker */item[/* activeTracker */4]
                                                ];
                                        } else {
                                          return item;
                                        }
                                      }))]);
                  case 7 : 
                      var trackerId$1 = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                updateItemInState(state, trackerId$1, (function (item) {
                                        return /* record */[
                                                /* trackerId */item[/* trackerId */0],
                                                /* vehicleId */item[/* vehicleId */1],
                                                /* edit */item[/* edit */2],
                                                /* state : Submitting */1,
                                                /* activeTracker */item[/* activeTracker */4]
                                              ];
                                      })),
                                (function (self) {
                                    return Repromise.Rejectable[/* wait */6]((function (result) {
                                                  if (result.tag) {
                                                    return Curry._1(self[/* send */3], /* MappingError */Block.__(9, [
                                                                  trackerId$1,
                                                                  result[0]
                                                                ]));
                                                  } else {
                                                    return Curry._1(self[/* send */3], /* MappingDeleted */Block.__(8, [trackerId$1]));
                                                  }
                                                }), Api_Requests.deleteTrackerMapping(trackerId$1, axios, authContext[/* refreshToken */1]));
                                  })
                              ]);
                  case 8 : 
                      var trackerId$2 = action[0];
                      return /* Update */Block.__(0, [/* record */[
                                  /* items */Belt_List.keepMap(state[/* items */0], (function (item) {
                                          if (item[/* trackerId */0] !== trackerId$2) {
                                            return item;
                                          } else if (item[/* activeTracker */4]) {
                                            return /* record */[
                                                    /* trackerId */item[/* trackerId */0],
                                                    /* vehicleId */undefined,
                                                    /* edit */undefined,
                                                    /* state : UserInput */0,
                                                    /* activeTracker */item[/* activeTracker */4]
                                                  ];
                                          } else {
                                            return undefined;
                                          }
                                        })),
                                  /* error */state[/* error */1],
                                  /* loading */state[/* loading */2]
                                ]]);
                  case 9 : 
                      var error = action[1];
                      return /* Update */Block.__(0, [updateItemInState(state, action[0], (function (item) {
                                        return /* record */[
                                                /* trackerId */item[/* trackerId */0],
                                                /* vehicleId */item[/* vehicleId */1],
                                                /* edit */item[/* edit */2],
                                                /* state : Error */[error],
                                                /* activeTracker */item[/* activeTracker */4]
                                              ];
                                      }))]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.LocalStyles = LocalStyles;
exports.canSubmitForSave = canSubmitForSave;
exports.makeTrackerMapping = makeTrackerMapping;
exports.fetch = fetch;
exports.loadingElement = loadingElement;
exports.noItemsElement = noItemsElement;
exports.errorElement = errorElement;
exports.listElement = listElement;
exports.component = component;
exports.updateItemInState = updateItemInState;
exports.make = make;
/* notMapped Not a pure module */
