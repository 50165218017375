// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var WMenuModal = require("./WMenuModal.bs.js");
var LicenseList = require("../../LicenseList.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Utils_React = require("../../common/utils/Utils_React.bs.js");
var WStyleUtils = require("../utils/WStyleUtils.bs.js");

var ccaUrl = "https://www.cca.io";

function isValidCopyrightUrl(url) {
  return url.startsWith("http");
}

var component = ReasonReact.statelessComponent("CopyrightModal");

function make(theme, open_, dispatch, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, WMenuModal.make(open_, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.title", "Copyright", undefined, undefined, /* array */[])), dispatch, /* array */[React.createElement("div", undefined, React.createElement("div", {
                                        className: WMenuModal.Styles[/* subHeading */0]
                                      }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.ccaCopyright", "Copyright", undefined, undefined, /* array */[])), " ", React.createElement("a", {
                                            className: WStyleUtils.tintLinkClass(theme),
                                            href: ccaUrl,
                                            rel: "noopener noreferrer",
                                            target: "_blank"
                                          }, "Control Center Apps GmbH.")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.ccaAllRights", "All rights reserved.", undefined, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.registeredAt", "Registered at the Commercial Court Vienna, Reg. No. FN 397984 x", undefined, undefined, /* array */[]))), React.createElement("h4", {
                                        className: WMenuModal.Styles[/* subHeading */0]
                                      }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.mobileServices", "CCA Mobile Services Platform", undefined, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.mobileServicesCopyright", "Copyright", undefined, undefined, /* array */[])), " ", React.createElement("a", {
                                            className: WStyleUtils.tintLinkClass(theme),
                                            href: ccaUrl,
                                            rel: "noopener noreferrer",
                                            target: "_blank"
                                          }, "Control Center Apps GmbH.")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.mobileServicesAllRights", "All rights reserved.", undefined, undefined, /* array */[]))), React.createElement("h4", {
                                        className: WMenuModal.Styles[/* subHeading */0]
                                      }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.openSource.shared", "Open source licenses", undefined, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.thirdParty", "This application uses the following third party libraries:", undefined, undefined, /* array */[]))), Utils_React.mapListToReactElements(LicenseList.licenses, (function (item) {
                                          var match = item[/* url */3].startsWith("http");
                                          return React.createElement("div", {
                                                      key: item[/* name */0]
                                                    }, React.createElement("h4", {
                                                          className: WMenuModal.Styles[/* subHeading */0]
                                                        }, item[/* name */0]), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.thirdParty.version", "Version: {version}", {
                                                                  version: item[/* version */1]
                                                                }, undefined, /* array */[]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("Copyright.thirdParty.license", "License: {license}", {
                                                                  license: item[/* licenseType */2]
                                                                }, undefined, /* array */[]))), match ? React.createElement("div", undefined, React.createElement("a", {
                                                                href: item[/* url */3]
                                                              }, item[/* url */3])) : null);
                                        })))]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Styles = 0;

exports.Styles = Styles;
exports.ccaUrl = ccaUrl;
exports.isValidCopyrightUrl = isValidCopyrightUrl;
exports.component = component;
exports.make = make;
/* component Not a pure module */
