// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");

function valueFromEvent(evt) {
  return evt.target.value;
}

var listToElement = Belt_List.toArray;

function mapListToReactElements(list, f) {
  return Belt_List.toArray(Belt_List.map(list, f));
}

function mapListToReactElementsWithIndex(list, f) {
  return Belt_List.toArray(Belt_List.mapWithIndex(list, f));
}

var nbsp = "\u00A0";

exports.valueFromEvent = valueFromEvent;
exports.listToElement = listToElement;
exports.mapListToReactElements = mapListToReactElements;
exports.mapListToReactElementsWithIndex = mapListToReactElementsWithIndex;
exports.nbsp = nbsp;
/* No side effect */
