// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function MakeRemoteStore(Params) {
  var fetch = function (self, fetcher) {
    Curry._1(self[/* send */3], /* Loading */0);
    return Repromise.wait((function (result) {
                  if (result.tag) {
                    return Curry._1(self[/* send */3], /* FetchError */Block.__(1, [result[0]]));
                  } else {
                    return Curry._1(self[/* send */3], /* Fetched */Block.__(0, [result[0]]));
                  }
                }), Curry._1(fetcher, self[/* state */1][/* params */1]));
  };
  var component = ReasonReact.reducerComponent(Params[/* componentName */2]);
  var make = function (params, fetcher, loadingElement, errorElement, listElement, noItemsElement, _) {
    return /* record */[
            /* debugName */component[/* debugName */0],
            /* reactClassInternal */component[/* reactClassInternal */1],
            /* handedOffState */component[/* handedOffState */2],
            /* willReceiveProps */(function (self) {
                var init = self[/* state */1];
                return /* record */[
                        /* items */init[/* items */0],
                        /* params */params,
                        /* error */init[/* error */2],
                        /* loading */init[/* loading */3]
                      ];
              }),
            /* didMount */(function (self) {
                return fetch(self, fetcher);
              }),
            /* didUpdate */(function (param) {
                var newSelf = param[/* newSelf */1];
                if (Curry._2(Params[/* paramsEqual */0], param[/* oldSelf */0][/* state */1][/* params */1], newSelf[/* state */1][/* params */1])) {
                  return 0;
                } else {
                  return fetch(newSelf, fetcher);
                }
              }),
            /* willUnmount */component[/* willUnmount */6],
            /* willUpdate */component[/* willUpdate */7],
            /* shouldUpdate */component[/* shouldUpdate */8],
            /* render */(function (self) {
                var tmp;
                if (self[/* state */1][/* loading */3]) {
                  tmp = loadingElement;
                } else {
                  var match = self[/* state */1][/* error */2];
                  tmp = match !== undefined ? Curry._1(errorElement, match) : (
                      Belt_List.length(self[/* state */1][/* items */0]) > 0 ? Curry._1(listElement, self[/* state */1][/* items */0]) : noItemsElement
                    );
                }
                return React.createElement(React.Fragment, undefined, tmp);
              }),
            /* initialState */(function () {
                return /* record */[
                        /* items : [] */0,
                        /* params */params,
                        /* error */undefined,
                        /* loading */false
                      ];
              }),
            /* retainedProps */component[/* retainedProps */11],
            /* reducer */(function (action, state) {
                if (typeof action === "number") {
                  return /* Update */Block.__(0, [/* record */[
                              /* items */state[/* items */0],
                              /* params */state[/* params */1],
                              /* error */undefined,
                              /* loading */true
                            ]]);
                } else if (action.tag) {
                  return /* Update */Block.__(0, [/* record */[
                              /* items */state[/* items */0],
                              /* params */state[/* params */1],
                              /* error */action[0],
                              /* loading */false
                            ]]);
                } else {
                  return /* Update */Block.__(0, [/* record */[
                              /* items */Belt_List.sort(action[0], Params[/* compareItems */1]),
                              /* params */state[/* params */1],
                              /* error */undefined,
                              /* loading */false
                            ]]);
                }
              }),
            /* jsElementWrapped */component[/* jsElementWrapped */13]
          ];
  };
  return /* module */[
          /* fetch */fetch,
          /* component */component,
          /* make */make
        ];
}

exports.MakeRemoteStore = MakeRemoteStore;
/* react Not a pure module */
