import React from 'react';
import DateTimePicker from 'react-datetime-picker';

export default function OurDateTimePicker(props) {
  return (
    <DateTimePicker
      showLeadingZeros={true}
      disableClock={true}
      clearIcon={props.required ? null : DateTimePicker.defaultProps.clearIcon}
      returnValue="start"
      {...props}
    />
  );
}
