// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/src/ReasonReact.js");
var Styles = require("@material-ui/core/styles");

function make(theme, children) {
  return ReasonReact.wrapJsForReason(Styles.MuiThemeProvider, {
              theme: theme
            }, children);
}

var Provider = /* module */[/* make */make];

exports.Provider = Provider;
/* ReasonReact Not a pure module */
