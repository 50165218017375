// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var WRouting = require("./WRouting.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WMainScreen = require("../main/WMainScreen.bs.js");
var WTrainScreen = require("../trains/WTrainScreen.bs.js");
var WTrainsScreen = require("../trains/WTrainsScreen.bs.js");
var WTrackerScreen = require("../trackers/WTrackerScreen.bs.js");
var WTrackersScreen = require("../trackers/WTrackersScreen.bs.js");
var WLocationsScreen = require("../location/WLocationsScreen.bs.js");
var WTrackerMappingsScreen = require("../trackers/WTrackerMappingsScreen.bs.js");

var component = ReasonReact.statelessComponent("WRoutes");

function make(syncContext, axios, authContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Curry._1(WRouting.Router[/* Container */0][/* make */1], (function (currentRoute) {
                                if (typeof currentRoute === "number") {
                                  switch (currentRoute) {
                                    case 0 : 
                                        return ReasonReact.element(undefined, undefined, WMainScreen.make(authContext[/* userInfo */0], /* array */[]));
                                    case 1 : 
                                        return ReasonReact.element(undefined, undefined, WLocationsScreen.make(syncContext, /* array */[]));
                                    case 2 : 
                                        return ReasonReact.element(undefined, undefined, WTrackerMappingsScreen.make(axios, authContext, /* array */[]));
                                    case 3 : 
                                    case 4 : 
                                    case 5 : 
                                        return WRouting.RouterConfig[/* redirect */3](/* Main */0);
                                    
                                  }
                                } else {
                                  switch (currentRoute.tag | 0) {
                                    case 0 : 
                                        return ReasonReact.element(undefined, undefined, WTrackersScreen.make(currentRoute[0], currentRoute[1], axios, authContext, /* array */[]));
                                    case 1 : 
                                        return ReasonReact.element(undefined, undefined, WTrainsScreen.make(currentRoute[0], axios, authContext, /* array */[]));
                                    case 2 : 
                                        var match = currentRoute[0];
                                        if (match.tag) {
                                          return ReasonReact.element(undefined, undefined, WTrainScreen.make(match[0], match[1], axios, authContext, /* array */[]));
                                        } else {
                                          return ReasonReact.element(undefined, undefined, WTrackerScreen.make(match[0], match[1], match[2], axios, authContext, /* array */[]));
                                        }
                                    
                                  }
                                }
                              })));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
