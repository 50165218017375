// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';


function fullMessage(msg, obj) {
  var match = JSON.stringify(obj);
  if (match !== undefined) {
    return msg + (" - " + match);
  } else {
    return msg;
  }
}

function debug(msg) {
  if (process.env.NODE_ENV === "development") {
    console.log(msg);
    return /* () */0;
  } else {
    return 0;
  }
}

function info(msg) {
  console.info(msg);
  return /* () */0;
}

function warn(msg) {
  console.warn(msg);
  return /* () */0;
}

function error(msg) {
  console.warn(msg);
  return /* () */0;
}

function debug2(msg, obj) {
  if (process.env.NODE_ENV === "development") {
    console.log(msg, obj);
    return /* () */0;
  } else {
    return 0;
  }
}

function info2(msg, obj) {
  console.info(msg, obj);
  return /* () */0;
}

function warn2(msg, obj) {
  console.warn(msg, obj);
  return /* () */0;
}

function error2(msg, obj) {
  console.error(msg, obj);
  return /* () */0;
}

exports.fullMessage = fullMessage;
exports.debug = debug;
exports.info = info;
exports.warn = warn;
exports.error = error;
exports.debug2 = debug2;
exports.info2 = info2;
exports.warn2 = warn2;
exports.error2 = error2;
/* No side effect */
