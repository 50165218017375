// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Axios = require("axios");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Api_Error = require("./Api_Error.bs.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");

var baseURL = "/api/v1";

function noResult(promise) {
  return Repromise.Rejectable[/* catch */7]((function (error) {
                return Repromise.resolved(/* Error */Block.__(1, [Api_Error.fetchError(error)]));
              }), Repromise.Rejectable[/* map */5]((function () {
                    return /* Ok */Block.__(0, [/* () */0]);
                  }), promise));
}

function decodeResult(decoder, promise) {
  return Repromise.Rejectable[/* catch */7]((function (error) {
                return Repromise.resolved(/* Error */Block.__(1, [Api_Error.fetchError(error)]));
              }), Repromise.Rejectable[/* map */5]((function (res) {
                    try {
                      return /* Ok */Block.__(0, [Curry._1(decoder, res.data)]);
                    }
                    catch (raw_exn){
                      var exn = Js_exn.internalToOCamlException(raw_exn);
                      if (exn[0] === Json_decode.DecodeError) {
                        return /* Error */Block.__(1, [Api_Error.jsonDecodeError(exn[1])]);
                      } else {
                        throw exn;
                      }
                    }
                  }), promise));
}

function decodeResultAndLastModified(decoder, promise) {
  return Repromise.Rejectable[/* catch */7]((function (error) {
                return Repromise.resolved(/* Error */Block.__(1, [Api_Error.fetchError(error)]));
              }), Repromise.Rejectable[/* map */5]((function (res) {
                    try {
                      return /* Ok */Block.__(0, [/* tuple */[
                                  Curry._1(decoder, res.data),
                                  Js_primitive.undefined_to_opt(res.headers["last-modified"])
                                ]]);
                    }
                    catch (raw_exn){
                      var exn = Js_exn.internalToOCamlException(raw_exn);
                      if (exn[0] === Json_decode.DecodeError) {
                        return /* Error */Block.__(1, [Api_Error.jsonDecodeError(exn[1])]);
                      } else {
                        throw exn;
                      }
                    }
                  }), promise));
}

function makeAxios(serverUrl) {
  var baseURL$1 = serverUrl + baseURL;
  return Axios.create({
              baseURL: baseURL$1,
              timeout: 30000
            });
}

function getAxiosConfig(contentType, ifModifiedSince, accessToken) {
  var tmp = {
    authorization: "Bearer " + accessToken
  };
  if (contentType !== undefined) {
    tmp["content-type"] = Js_primitive.valFromOption(contentType);
  }
  if (ifModifiedSince !== undefined) {
    tmp["if-modified-since"] = Js_primitive.valFromOption(ifModifiedSince);
  }
  var headers = tmp;
  return {
          headers: headers
        };
}

var defaultTimeout = 30000;

var minTokenValiditySeconds = 30;

exports.baseURL = baseURL;
exports.defaultTimeout = defaultTimeout;
exports.minTokenValiditySeconds = minTokenValiditySeconds;
exports.noResult = noResult;
exports.decodeResult = decodeResult;
exports.decodeResultAndLastModified = decodeResultAndLastModified;
exports.makeAxios = makeAxios;
exports.getAxiosConfig = getAxiosConfig;
/* axios Not a pure module */
