import * as React from 'react';
import ReactDOM from 'react-dom';

import WApp from './WApp.bs';

export function startApplication(appContext) {
  const rootElement = document.getElementById('root');
  if (rootElement) {
    const render = () => {
      ReactDOM.render(<WApp appContext={appContext} />, rootElement);
    };

    if (module.hot) {
      module.hot.accept('./WApp.bs', () => {
        setTimeout(render);
      });
    }

    render();
  }
}
