// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Dexie = require("../bindings/Dexie.bs.js");
var Dexie$1 = require("dexie");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Db_Error = require("./Db_Error.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");

var db = new Dexie$1.default("train_tracker");

db.version(1).stores(Js_dict.fromList(/* :: */[
          /* tuple */[
            "installation",
            ""
          ],
          /* :: */[
            /* tuple */[
              "settings",
              ""
            ],
            /* :: */[
              /* tuple */[
                "incidents",
                "incidentId"
              ],
              /* [] */0
            ]
          ]
        ]));

var installation = db.table("installation");

var settings = db.table("settings");

var incidents = db.table("incidents");

var Tables = /* module */[
  /* installation */installation,
  /* settings */settings,
  /* incidents */incidents
];

function decodeResult(decoder, result) {
  if (result !== undefined) {
    try {
      return /* Ok */Block.__(0, [Js_primitive.some(Curry._1(decoder, Js_primitive.valFromOption(result)))]);
    }
    catch (raw_exn){
      var exn = Js_exn.internalToOCamlException(raw_exn);
      if (exn[0] === Json_decode.DecodeError) {
        return /* Error */Block.__(1, [Db_Error.jsonDecodeError(exn[1])]);
      } else {
        throw exn;
      }
    }
  } else {
    return /* Ok */Block.__(0, [undefined]);
  }
}

function decodeResultArray(decoder, res) {
  try {
    return /* Ok */Block.__(0, [Belt_List.fromArray(Belt_Array.map(res, decoder))]);
  }
  catch (raw_exn){
    var exn = Js_exn.internalToOCamlException(raw_exn);
    if (exn[0] === Json_decode.DecodeError) {
      return /* Error */Block.__(1, [Db_Error.jsonDecodeError(exn[1])]);
    } else {
      throw exn;
    }
  }
}

function handleDbError(error) {
  return Promise.resolve(/* Error */Block.__(1, [Db_Error.dbError(String(error))]));
}

function transaction(mode, tables, body) {
  return db.transaction((function () {
                      switch (mode) {
                        case -240492574 : 
                            return "r";
                        case 557889961 : 
                            return "rw";
                        
                      }
                    })(), Belt_List.toArray(tables), body).then((function (result) {
                  return Promise.resolve(/* Ok */Block.__(0, [result]));
                })).catch(handleDbError);
}

function transactionExn(mode, tables, body) {
  return db.transaction((function () {
                  switch (mode) {
                    case -240492574 : 
                        return "r";
                    case 557889961 : 
                        return "rw";
                    
                  }
                })(), Belt_List.toArray(tables), body);
}

function putExn(table, encode, value) {
  return table.put(Curry._1(encode, value)).then((function () {
                return Promise.resolve(/* () */0);
              }));
}

function put(table, encode, value) {
  return table.put(Curry._1(encode, value)).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function putWithKey(table, key, encode, value) {
  return table.put(Curry._1(encode, value), key).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function putWithKeyExn(table, key, encode, value) {
  return table.put(Curry._1(encode, value), key).then((function () {
                return Promise.resolve(/* () */0);
              }));
}

function bulkPut(table, encode, values) {
  return table.bulkPut(Belt_List.toArray(Belt_List.map(values, encode))).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function bulkPutExn(table, encode, values) {
  return table.bulkPut(Belt_List.toArray(Belt_List.map(values, encode)));
}

function bulkPutWithKeys(table, encode, values, keys) {
  return table.bulkPut(Belt_List.toArray(Belt_List.map(values, encode)), Belt_List.toArray(keys)).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function bulkPutWithKeysExn(table, encode, values, keys) {
  return table.bulkPut(Belt_List.toArray(Belt_List.map(values, encode)), Belt_List.toArray(keys)).then((function () {
                return Promise.resolve(/* () */0);
              }));
}

function bulkPutEncodedItems(table, items) {
  var keys = Belt_List.toArray(Belt_List.map(items, (function (param) {
              return param[0];
            })));
  var values = Belt_List.toArray(Belt_List.map(items, (function (param) {
              return param[1];
            })));
  return table.bulkPut(values, keys).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function bulkPutEncodedItemsExn(table, items) {
  var keys = Belt_List.toArray(Belt_List.map(items, (function (param) {
              return param[0];
            })));
  var values = Belt_List.toArray(Belt_List.map(items, (function (param) {
              return param[1];
            })));
  return table.bulkPut(values, keys).then((function () {
                return Promise.resolve(/* () */0);
              }));
}

function putBlobExn(table, value, key) {
  return table.put(value, key).then((function () {
                return Promise.resolve(/* () */0);
              }));
}

function putBlob(table, value, key) {
  return table.put(value, key).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function bulkPutBlobsExn(table, values, keys) {
  return table.bulkPut(Belt_List.toArray(values), Belt_List.toArray(keys)).then((function () {
                return Promise.resolve(/* () */0);
              }));
}

function getExn(table, key, decode) {
  return Dexie.Table[/* get */0](table, key).then((function (res) {
                return Promise.resolve(Belt_Option.map(res, decode));
              }));
}

function get(table, key, decode) {
  return Dexie.Table[/* get */0](table, key).then((function (res) {
                  return Promise.resolve(decodeResult(decode, res));
                })).catch(handleDbError);
}

function getBlob(table, key) {
  return Dexie.Table[/* getBlob */1](table, key).then((function (blob) {
                  return Promise.resolve(/* Ok */Block.__(0, [blob]));
                })).catch(handleDbError);
}

function getBlobExn(table, key) {
  return Dexie.Table[/* getBlob */1](table, key).then((function (res) {
                return Promise.resolve(res);
              }));
}

function $$delete(table, key) {
  return table.delete(key).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function deleteExn(table, key) {
  return table.delete(key);
}

function bulkDelete(table, keys) {
  return table.bulkDelete(Belt_List.toArray(keys)).then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function deleteByKey(table, key, value) {
  return table.where(key).equals(value).delete().then((function (num) {
                  return Promise.resolve(/* Ok */Block.__(0, [num]));
                })).catch(handleDbError);
}

function toList(table, decode) {
  return table.toArray().then((function (array) {
                  return Promise.resolve(decodeResultArray(decode, array));
                })).catch(handleDbError);
}

function toListExn(table, decode) {
  return table.toArray().then((function (array) {
                return Promise.resolve(Belt_List.map(Belt_List.fromArray(array), decode));
              }));
}

function listByKey(table, decode, key, value) {
  return value.equals(table.where(key)).toArray().then((function (array) {
                  return Promise.resolve(decodeResultArray(decode, array));
                })).catch(handleDbError);
}

function clear(table) {
  return table.clear().then((function () {
                  return Promise.resolve(/* Ok */Block.__(0, [/* () */0]));
                })).catch(handleDbError);
}

function clearExn(table) {
  return table.clear();
}

var Table = /* module */[
  /* putExn */putExn,
  /* put */put,
  /* putWithKey */putWithKey,
  /* putWithKeyExn */putWithKeyExn,
  /* bulkPut */bulkPut,
  /* bulkPutExn */bulkPutExn,
  /* bulkPutWithKeys */bulkPutWithKeys,
  /* bulkPutWithKeysExn */bulkPutWithKeysExn,
  /* bulkPutEncodedItems */bulkPutEncodedItems,
  /* bulkPutEncodedItemsExn */bulkPutEncodedItemsExn,
  /* putBlobExn */putBlobExn,
  /* putBlob */putBlob,
  /* bulkPutBlobsExn */bulkPutBlobsExn,
  /* getExn */getExn,
  /* get */get,
  /* getBlob */getBlob,
  /* getBlobExn */getBlobExn,
  /* delete */$$delete,
  /* deleteExn */deleteExn,
  /* bulkDelete */bulkDelete,
  /* deleteByKey */deleteByKey,
  /* toList */toList,
  /* toListExn */toListExn,
  /* listByKey */listByKey,
  /* clear */clear,
  /* clearExn */clearExn
];

var $$Error = 0;

exports.db = db;
exports.Tables = Tables;
exports.decodeResult = decodeResult;
exports.decodeResultArray = decodeResultArray;
exports.handleDbError = handleDbError;
exports.transaction = transaction;
exports.transactionExn = transactionExn;
exports.Table = Table;
exports.$$Error = $$Error;
/* db Not a pure module */
