// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Time = require("../../common/types/Time.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Styles = require("../css/Styles.bs.js");
var ReactIntl = require("bs-react-intl/src/ReactIntl.bs.js");
var Repromise = require("@aantron/repromise/src/js/repromise.js");
var WTrackMap = require("./WTrackMap.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var RemoteStore = require("../components/RemoteStore.bs.js");

var container = Styles.DataView[/* container */0];

var message = Styles.DataView[/* message */1];

var Styles$1 = /* module */[
  /* container */container,
  /* message */message
];

function MakeTrackMap(Params) {
  var paramsEqual = Params[/* paramsEqual */0];
  var compareItems = function (a, b) {
    return Time.Instant[/* compare */6](a[/* gpsTime */4], b[/* gpsTime */4]);
  };
  var WaypointsRemoteStore = RemoteStore.MakeRemoteStore(/* module */[
        /* paramsEqual */paramsEqual,
        /* compareItems */compareItems,
        /* componentName */"WaypointsRemoteStore"
      ]);
  var component = ReasonReact.statelessComponent("WTrackMapStore");
  var make = function (params, fetcher, $staropt$star, width, height, _) {
    var onSummary = $staropt$star !== undefined ? $staropt$star : (function () {
          return /* () */0;
        });
    return /* record */[
            /* debugName */component[/* debugName */0],
            /* reactClassInternal */component[/* reactClassInternal */1],
            /* handedOffState */component[/* handedOffState */2],
            /* willReceiveProps */component[/* willReceiveProps */3],
            /* didMount */component[/* didMount */4],
            /* didUpdate */component[/* didUpdate */5],
            /* willUnmount */component[/* willUnmount */6],
            /* willUpdate */component[/* willUpdate */7],
            /* shouldUpdate */component[/* shouldUpdate */8],
            /* render */(function () {
                var fetcher$1 = function (p) {
                  return Repromise.map((function (result) {
                                var fn = function (param) {
                                  if (param !== undefined) {
                                    var match = param;
                                    var vehicleId = match[/* vehicleId */4];
                                    var trackerId = match[/* trackerId */3];
                                    var lastGpsTime = match[/* lastGpsTime */2];
                                    var firstGpsTime = match[/* firstGpsTime */1];
                                    var numberOfWaypoints = match[/* numberOfWaypoints */0];
                                    return (function (waypoint) {
                                        return /* record */[
                                                /* numberOfWaypoints */numberOfWaypoints + 1 | 0,
                                                /* firstGpsTime */Time.Instant[/* min */8](firstGpsTime, waypoint[/* gpsTime */4]),
                                                /* lastGpsTime */Time.Instant[/* max */9](lastGpsTime, waypoint[/* gpsTime */4]),
                                                /* trackerId */trackerId,
                                                /* vehicleId */vehicleId
                                              ];
                                      });
                                  } else {
                                    return (function (param) {
                                        var gpsTime = param[/* gpsTime */4];
                                        return /* record */[
                                                /* numberOfWaypoints */1,
                                                /* firstGpsTime */gpsTime,
                                                /* lastGpsTime */gpsTime,
                                                /* trackerId */param[/* trackerId */0],
                                                /* vehicleId */param[/* vehicleId */1]
                                              ];
                                      });
                                  }
                                };
                                var summary;
                                summary = result.tag ? undefined : List.fold_left(fn, undefined, result[0]);
                                Curry._1(onSummary, summary);
                                return result;
                              }), Curry._1(fetcher, p));
                };
                var loadingElement = React.createElement("div", {
                      className: message
                    }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackMapStore.loading", "Loading", undefined, undefined, /* array */[])));
                var noItemsElement = React.createElement("div", {
                      className: message
                    }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackMapStore.noWaypoints", "No waypoints found", undefined, undefined, /* array */[])));
                var errorElement = function () {
                  return React.createElement("div", {
                              className: message
                            }, ReasonReact.element(undefined, undefined, ReactIntl.FormattedMessage[/* make */0]("WTrackMapStore.error", "Error on fetching waypoints", undefined, undefined, /* array */[])));
                };
                var listElement = function (waypoints) {
                  return ReasonReact.element(undefined, undefined, WTrackMap.make(waypoints, width, height, /* array */[]));
                };
                return ReasonReact.element(undefined, undefined, Curry._7(WaypointsRemoteStore[/* make */2], params, fetcher$1, loadingElement, errorElement, listElement, noItemsElement, /* array */[]));
              }),
            /* initialState */component[/* initialState */10],
            /* retainedProps */component[/* retainedProps */11],
            /* reducer */component[/* reducer */12],
            /* jsElementWrapped */component[/* jsElementWrapped */13]
          ];
  };
  return /* module */[
          /* WaypointsRemoteStore */WaypointsRemoteStore,
          /* component */component,
          /* make */make
        ];
}

exports.Styles = Styles$1;
exports.MakeTrackMap = MakeTrackMap;
/* Time Not a pure module */
