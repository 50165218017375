// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("./types/Time.bs.js");
var Api_Common = require("./api/Api_Common.bs.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("ExportLink.Tracker");

function make(type_, trackerId, from, to_, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReactDOMRe.createElementVariadic("a", {
                          href: Api_Common.baseURL + ("/trackers/" + (trackerId + ("/track." + (type_ + ("?from=" + (Time.Instant[/* toISOString */4](from) + Belt_Option.mapWithDefault(to_, "", (function (to_) {
                                              return "&to=" + Time.Instant[/* toISOString */4](to_);
                                            })))))))),
                          onClick: (function (e) {
                              e.stopPropagation();
                              return /* () */0;
                            })
                        }, _children);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var Tracker = /* module */[
  /* component */component,
  /* make */make
];

var component$1 = ReasonReact.statelessComponent("ExportLink.Train");

function make$1(type_, trainNumber, operationDay, _children) {
  return /* record */[
          /* debugName */component$1[/* debugName */0],
          /* reactClassInternal */component$1[/* reactClassInternal */1],
          /* handedOffState */component$1[/* handedOffState */2],
          /* willReceiveProps */component$1[/* willReceiveProps */3],
          /* didMount */component$1[/* didMount */4],
          /* didUpdate */component$1[/* didUpdate */5],
          /* willUnmount */component$1[/* willUnmount */6],
          /* willUpdate */component$1[/* willUpdate */7],
          /* shouldUpdate */component$1[/* shouldUpdate */8],
          /* render */(function () {
              return ReactDOMRe.createElementVariadic("a", {
                          href: Api_Common.baseURL + ("/trains/" + (Time.LocalDate[/* toISOString */5](operationDay) + ("/" + (trainNumber + ("/track." + type_))))),
                          onClick: (function (e) {
                              e.stopPropagation();
                              return /* () */0;
                            })
                        }, _children);
            }),
          /* initialState */component$1[/* initialState */10],
          /* retainedProps */component$1[/* retainedProps */11],
          /* reducer */component$1[/* reducer */12],
          /* jsElementWrapped */component$1[/* jsElementWrapped */13]
        ];
}

var Train = /* module */[
  /* component */component$1,
  /* make */make$1
];

exports.Tracker = Tracker;
exports.Train = Train;
/* component Not a pure module */
