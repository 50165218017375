// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Colors = require("../../common/Colors.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var labeledValue = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* [] */0
    ]);

var label = Css.style(/* :: */[
      Css.textTransform(/* uppercase */-415330030),
      /* :: */[
        Css.color(Css.hex(Colors.darkGreyText)),
        /* [] */0
      ]
    ]);

var value = Css.style(/* [] */0);

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.padding2(Css.em(0.4), Css.em(0.3)),
          /* :: */[
            Css.flexShrink(0),
            /* :: */[
              Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors.border)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = /* module */[
  /* labeledValue */labeledValue,
  /* label */label,
  /* value */value,
  /* container */container
];

var component = ReasonReact.statelessComponent("LabeledValue");

function make(label$1, value$1, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", {
                          className: labeledValue
                        }, React.createElement("div", {
                              className: label
                            }, label$1), React.createElement("div", {
                              className: value
                            }, value$1));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* labeledValue Not a pure module */
