// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../common/types/Time.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_null = require("bs-platform/lib/js/js_null.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Js_primitive = require("bs-platform/lib/js/js_primitive.js");
var DateTimePicker = require("./DateTimePicker");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");

function make(onChange, value, maxDate, minDate, className, required, disabled, children) {
  return ReasonReact.wrapJsForReason(DateTimePicker.default, {
              onChange: (function (value) {
                  return Curry._1(onChange, Belt_Option.map((value == null) ? undefined : Js_primitive.some(value), Time.Instant[/* fromJsDate */0]));
                }),
              disabled: Js_null_undefined.fromOption(disabled),
              required: Js_null_undefined.fromOption(required),
              className: Js_null_undefined.fromOption(className),
              value: Js_null.fromOption(Belt_Option.map(value, Time.Instant[/* toJsDate */1])),
              maxDate: Js_null.fromOption(Belt_Option.map(maxDate, Time.Instant[/* toJsDate */1])),
              minDate: Js_null.fromOption(Belt_Option.map(minDate, Time.Instant[/* toJsDate */1]))
            }, children);
}

exports.make = make;
/* Time Not a pure module */
