// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/src/ReasonReact.js");
var Utils_React = require("../../common/utils/Utils_React.bs.js");
var WAuthManager = require("../auth/WAuthManager.bs.js");
var WNavbarExternalLinkItem = require("./WNavbarExternalLinkItem.bs.js");
var WNavbarInternalLinkItem = require("./WNavbarInternalLinkItem.bs.js");

var component = ReasonReact.statelessComponent("WNavbarConfigItems");

function getTitle(_, _$1) {
  return "TODO";
}

function make(navbarItems, _, userInfo, _$1) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              var locationRootUrl = window.location.origin + window.location.pathname;
              return Utils_React.mapListToReactElements(navbarItems, (function (item) {
                            if (WAuthManager.hasAnyRole(userInfo, item[/* roles */3])) {
                              var path = item[/* path */2];
                              var rootUrl = item[/* rootUrl */1];
                              var url = " " + (String(rootUrl) + ("#" + (String(path) + " ")));
                              var titleText = "TODO";
                              if (rootUrl === locationRootUrl) {
                                return ReasonReact.element(url, undefined, WNavbarInternalLinkItem.make(titleText, path, /* array */[]));
                              } else {
                                return ReasonReact.element(url, undefined, WNavbarExternalLinkItem.make(titleText, url, /* array */[]));
                              }
                            } else {
                              return null;
                            }
                          }));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var fallbackLocale = "en";

exports.component = component;
exports.fallbackLocale = fallbackLocale;
exports.getTitle = getTitle;
exports.make = make;
/* component Not a pure module */
