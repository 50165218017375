// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Colors = require("../../common/Colors.bs.js");

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexShrink(0),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.padding(Css.em(0.7)),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors.toolbarBackground)),
              /* :: */[
                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors.toolbarBorder)),
                /* :: */[
                  Css.selector(" > div", /* :: */[
                        Css.margin2(Css.zero, Css.em(0.3)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var spacer = Css.style(/* :: */[
      Css.flexGrow(1),
      /* [] */0
    ]);

var picker = Css.style(/* :: */[
      Css.backgroundColor(Css.white),
      /* [] */0
    ]);

var Toolbar = /* module */[
  /* container */container,
  /* spacer */spacer,
  /* picker */picker
];

var container$1 = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.overflow(/* auto */-1065951377),
        /* [] */0
      ]
    ]);

var message = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.color(Css.hex(Colors.lightGrey)),
        /* :: */[
          Css.fontSize(Css.vh(7.0)),
          /* :: */[
            Css.fontWeight(100),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.justifyContent(/* center */98248149),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]) + (" " + container$1);

var table = Css.style(/* :: */[
      Css.selector(" > table", /* :: */[
            Css.borderCollapse(/* collapse */-996847251),
            /* :: */[
              Css.width(/* `percent */[
                    -119887163,
                    100.0
                  ]),
              /* :: */[
                Css.selector(" th", /* :: */[
                      Css.textAlign(/* left */-944764921),
                      /* :: */[
                        Css.fontWeight(200),
                        /* :: */[
                          Css.borderColor(Css.hex(Colors.darkBackground)),
                          /* :: */[
                            Css.backgroundColor(Css.hex(Colors.darkBackground)),
                            /* :: */[
                              Css.color(Css.hex(Colors.lightGrey)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* :: */[
                  Css.selector(" th, td", /* :: */[
                        Css.padding(Css.em(0.5)),
                        /* :: */[
                          Css.border(Css.px(1), Css.solid, Css.hex(Colors.lightGrey)),
                          /* [] */0
                        ]
                      ]),
                  /* :: */[
                    Css.selector(" tr", /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* :: */[
                            Css.selector(":hover", /* :: */[
                                  Css.backgroundColor(Css.hex("ddd")),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]),
      /* [] */0
    ]) + (" " + container$1);

var DataView = /* module */[
  /* container */container$1,
  /* message */message,
  /* table */table
];

exports.Toolbar = Toolbar;
exports.DataView = DataView;
/* container Not a pure module */
