// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';


var darkToolbarBackground = "3a3a3a";

var toolbarBackground = "f0f0f0";

var toolbarBorder = "ababab";

var border = "ababab";

var separator = "d6d6d6";

var darkBackground = "333333";

var closedIncidentBackground = "f6f6f6";

var tableViewBackground = "efeff4";

var lightGrey = "c4c4c4";

var greyText = "959595";

var darkGreyText = "7b7b7b";

var greyBackground = "eeeeee";

var white = "ffffff";

var black = "000000";

var closedIncident = "bbbbbb";

var marker = "yellow";

var darkSeparator = "505050";

var cellSeparator = "333335";

var placeholder = "999999";

var defaultTintColor = "be112b";

var negative = "c00b25";

var positive = "93c01f";

var navbarBackground = darkToolbarBackground;

var activeNavItemColor = "999";

var activeNavItemIndicator = "e2002a";

exports.toolbarBackground = toolbarBackground;
exports.toolbarBorder = toolbarBorder;
exports.darkToolbarBackground = darkToolbarBackground;
exports.border = border;
exports.separator = separator;
exports.darkBackground = darkBackground;
exports.closedIncidentBackground = closedIncidentBackground;
exports.tableViewBackground = tableViewBackground;
exports.lightGrey = lightGrey;
exports.greyText = greyText;
exports.darkGreyText = darkGreyText;
exports.greyBackground = greyBackground;
exports.white = white;
exports.black = black;
exports.closedIncident = closedIncident;
exports.marker = marker;
exports.darkSeparator = darkSeparator;
exports.cellSeparator = cellSeparator;
exports.placeholder = placeholder;
exports.defaultTintColor = defaultTintColor;
exports.negative = negative;
exports.positive = positive;
exports.navbarBackground = navbarBackground;
exports.activeNavItemColor = activeNavItemColor;
exports.activeNavItemIndicator = activeNavItemIndicator;
/* No side effect */
