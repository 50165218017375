// Generated by BUCKLESCRIPT VERSION 4.0.6, PLEASE EDIT WITH CARE
'use strict';

var Resizer = require("../components/Resizer.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var WLocationsMap = require("./WLocationsMap.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");

var component = ReasonReact.statelessComponent("WLocationsScreen");

function make(syncContext, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(undefined, undefined, Resizer.make((function (width, height) {
                                return ReasonReact.element(undefined, undefined, WLocationsMap.make(Belt_MapString.valuesToArray(syncContext[/* locations */0]), width, height, /* array */[]));
                              })));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
